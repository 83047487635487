import { put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { updatePasswordApi } from './remoteApi';

function* executePasswordUpdate(action) {
    yield apiCall(updatePasswordApi, action.elementId, action.newPassword);
}

function* updatePasswordGenerator(action) {
    yield put(actions.startUpdatingPassword());
    yield guardedGenerator(executePasswordUpdate, action);
    yield put(actions.finishUpdatingPassword());
}

export function* watchUpdatePassword() {
    yield guardedTakeEvery(actionTypes.UPDATE_PASSWORD, updatePasswordGenerator);
}
