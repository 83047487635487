import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeRole, isMetaTypeUser } from 'infrastructure/helpers/metaTypes';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getResourcesState } from 'store/layout/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { updateRoleActivationOptionsApi, updateUserActivationOptionsApi } from './remoteApi';
import { fetchElementData } from '../element/actions';
import { refreshUserRoleParents } from '../sagaHelpers';

function* updateActivationOptionsInApi(action) {
    if (isMetaTypeRole(action.currentElement.metaTypeName)) {
        yield apiCall(updateRoleActivationOptionsApi, action.currentElement.id, action.activationOptions);
    }
    if (isMetaTypeUser(action.currentElement.metaTypeName)) {
        yield apiCall(updateUserActivationOptionsApi, action.currentElement.id, action.activationOptions);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.updateElementActivationOptions_SuccessMessage));
    yield put(actions.closeActivationOptionsModal());
    yield put(actions.cleanActivationOptionsModal());
    if (action.openingSource === 'elementForm') {
        yield put(fetchElementData(action.currentElement.id, action.currentElement.metaTypeName));
    }
    yield refreshUserRoleParents(action);
}

function* updateActivationOptions(action) {
    yield put(actions.startUpdatingActivationOptions());
    yield guardedGenerator(updateActivationOptionsInApi, action);
    yield put(actions.finishUpdatingActivationOptions());
}

export function* watchUpdateActivationOptions() {
    yield guardedTakeEvery(actionTypes.UPDATE_ACTIVATION_OPTIONS, updateActivationOptions);
}
