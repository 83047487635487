import { refreshCmsTreeElement } from 'api/cms/cmsTree/actions';
import { put, select } from 'redux-saga/effects';
import { getElementParents as getProductTreeElementParents } from 'store/catalog/productTree/selectors';
import { getElementParents as getCmsTreeElementParents } from 'store/cms/cmsTree/selectors';
import { refreshTreeElement } from './productTree/actions';

export function* refreshCatalogCmsParents(action, catalogArea) {
    if (action.currentElement) {
        if (action.elementArea === catalogArea) {
            const elementParents = yield select(getProductTreeElementParents, action.currentElement.id);
            // eslint-disable-next-line no-restricted-syntax
            for (const elementParent of elementParents) {
                yield put(refreshTreeElement(
                    elementParent.parentName,
                    elementParent.parentMetaTypeName
                ));
            }
        } else {
            const elementParents = yield select(getCmsTreeElementParents, action.currentElement.id);
            // eslint-disable-next-line no-restricted-syntax
            for (const elementParent of elementParents) {
                yield put(refreshCmsTreeElement(
                    elementParent.parentName,
                    elementParent.parentMetaTypeName
                ));
            }
        }
    }
}
