import React from 'react';
import { Route, Redirect } from 'react-router';
import * as tools from 'infrastructure/helpers/tools';
import { bool } from 'prop-types';

class PrivateRoute extends Route {
    render() {
        const { isAuthenticated, ...rest } = this.props;
        return (
            isAuthenticated
                ? <Route {...rest} {...this.context} />
                : <Redirect to={tools.getLoginPath(true)} />
        );
    }
}

PrivateRoute.propTypes = {
    isAuthenticated: bool.isRequired
};

export default PrivateRoute;
