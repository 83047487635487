import { authenticationContext } from 'infrastructure/authentication/authenticationContext';

export default {
    messagePlaceholder: {
        show: false,
        messages: [],
        message: '',
        messageType: ''
    },
    infrastructure: {
        image: ''
    },
    layout: {
        layoutInfo: {
            resources: {},
            menu: [],
            languages: [],
            supportedTranslations: []
        },
        isFetching: false
    },
    configuration: {
    },
    user: {
        data: {
            ...authenticationContext.getUserTokenData(),
            isAuthenticated: authenticationContext.isAuthenticated()
        },
        login: {
            isAuthenticating: false,
            authenticationFailed: false,
            isLoggingOut: false
        }
    },
    catalog: {
        element: {
            elementData: {
                name: '',
                displayName: '',
                associationGroups: [],
                startDate: '',
                endDate: '',
                isActive: false
            },
            isFetchingData: false,
            isSavingData: false
        },
        elementTypeConfiguration: {
            elementConfigurationData: {
                metaType: '',
                typeName: '',
                sections: [],
                properties: []
            },
            isFetchingConfigurationData: false
        },
        productTree: {
            tree: [],
            isFetching: false,
            isFetchingWholeTree: true,
            isRefreshing: true,
            config: null,
            isConfig: false
        },
        newElement: {
            availableTypes: [],
            isFetchingAvailableTypes: false,
            isCreatingNewElement: false,
            isModalOpen: false,
            currentElement: null
        },
        deleteElement: {
            isDeletingElement: false,
            isModalOpen: false,
            currentElement: null,
            deletionMethod: null
        },
        copyElement: {
            isCopyingElement: false,
            isModalOpen: false,
            currentElement: null,
            copyOption: null
        },
        changeElementType: {
            isModalOpen: false,
            availableTypes: [],
            isFetchingAvailableTypes: false,
            currentElement: null
        },
        changeElementId: {
            isModalOpen: false,
            isChangingElementId: false,
            currentElement: null
        },
        exportElement: {
            isModalOpen: false,
            isAddingExport: false,
            currentElement: null,
            availableExportDefinitions: [],
        },
        exportDefinition: {
            isExportDefinitionModalOpen: false,
            exportDefinitionsInitialOptions: {
                availableExportMetaTypes: null,
                availableLanguages: null
            },
            exportDefinitionsAvailableProperties: [],
        },
        activationOptions: {
            isModalOpen: false,
            isUpdatingActivationOptions: false,
            currentElement: null,
            openingSource: ''
        }
    },
    media: {
        elements: {
            elementsData: {
                medias: [],
                totalCount: 0
            },
            pageIndex: 0,
            isFetching: false,
            isSaving: false
        },
        element: {
            elementData: {
                name: '',
                displayName: '',
                associationGroups: [],
                startDate: '',
                endDate: '',
                isActive: false
            },
            isFetchingData: false,
            isSavingData: false,
            attachmentAssociations: [],
            isFetchingAssociations: false,
        },
        elementTypeConfiguration: {
            elementConfigurationData: {
                metaType: '',
                typeName: '',
                sections: [],
                properties: []
            },
            isFetchingConfigurationData: false
        },
        filter: {
            searchTerm: ''
        },
        uploadFiles: {
            namesInfo: null
        },
        deleteMediaElement: {
            isModalOpen: false
        },
        newMedias: {
            isFetching: false,
            mediaInfos: []
        },
        extensions: {
            supportedExtensions: []
        }
    },
    languageManagement: {
        filter: {
            searchTerm: ''
        },
        data: {
            isFetchingElements: false,
            isFetchingSearchTermData: false,
            data: []
        },
        export: {
            isFetchingExport: false,
            data: []
        }
    },
    dataManagement: {
        exportData: {
            isFetchingExports: false,
            isActionProcessing: false,
            data: []
        }
    },
    cms: {
        cmsTree: {
            tree: [],
            isFetching: false,
            isFetchingWholeTree: true,
            isRefreshing: true,
            config: null,
            isConfig: false
        }
    },
    userManagement: {
        element: {
            elementData: {
                name: '',
                displayName: '',
                associationGroups: [],
                startDate: '',
                endDate: '',
                isActive: false
            },
            isFetchingData: false,
            isSavingData: false
        },
        elementTypeConfiguration: {
            elementConfigurationData: {
                metaType: '',
                typeName: '',
                sections: [],
                properties: []
            },
            isFetchingConfigurationData: false
        },
        userManagementTree: {
            tree: [],
            isFetching: false,
            isFetchingWholeTree: true,
            isRefreshing: true,
            config: null,
            isConfig: false
        },
        newElement: {
            availableTypes: [],
            isFetchingAvailableTypes: false,
            isCreatingNewElement: false,
            isModalOpen: false,
            currentElement: null
        },
        deleteElement: {
            isDeletingElement: false,
            isModalOpen: false,
            currentElement: null,
            deletionMethod: null
        },
        copyElement: {
            isCopyingElement: false,
            isModalOpen: false,
            currentElement: null,
            copyOption: null
        },
        changeElementType: {
            isModalOpen: false,
            availableTypes: [],
            isFetchingAvailableTypes: false,
            currentElement: null
        },
        changeElementId: {
            isModalOpen: false,
            isChangingElementId: false,
            currentElement: null
        },
        changeUserEmail: {
            isModalOpen: false,
            isChangingUserEmail: false,
            currentElement: null
        },
        passwordUpdate: {
            isModalOpen: false,
            isUpdatingPassword: false,
            elementId: null
        },
        activationOptions: {
            isModalOpen: false,
            isUpdatingActivationOptions: false,
            currentElement: null,
            openingSource: ''
        }
    },
    search: {
        searchPhrase: '',
        isFetching: false,
        searchResults: {
            searchElements: [],
            totalCount: 0,
            modifiedElements: []
        },
        searchSuggestions: {},
        predefinedSearch: '',
        searchType: 'standard',
        predefinedSearchOptions: [],
        pageIndex: 0,
        pageSize: 20,
        isFetchingSuggestions: false
    },
    actions: {
        actions: [],
        icon: 'fal fa-magic text-primary',
        headingKey: 'actionManagementTitle',
        subheadingKey: 'actionManagementSubTitle'
    },
    element: {
        metatypeName: '',
        propertyData: {
            propertyName: '',
            suggestions: { searchPhrase: '', suggestionsElements: {} },
            isFetchingSuggestions: false
        }
    }
};
