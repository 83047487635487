import { put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getSearchResultsApi, getSearchSuggestionsApi, getPredefinedSearchResultsApi, getPredefinedSearchOptionsApi } from './remoteApi';

function* getSearchResults(action) {
    const searchRequest = {
        searchPhrase: action.searchPhrase,
        pageIndex: action.pageIndex,
        pageSize: action.pageSize,
        searchMetaTypes: action.searchMetaTypes,
        sortInfo: action.sortInfo,
        searchLanguage: action.searchLanguage
    };

    const results = yield apiCall(getSearchResultsApi, searchRequest);
    yield put(actions.initSearchResults(results));
}

function* fetchSearchResults(action) {
    yield put(actions.setPagination(action.pageIndex, action.pageSize));
    yield put(actions.startFetchingSearchResults());
    yield guardedGenerator(getSearchResults, action);
    yield put(actions.finishFetchingSearchResults());
}

export function* watchFetchSearchResults() {
    yield guardedTakeEvery(actionTypes.FETCH_SEARCH_RESULTS, fetchSearchResults);
}

function* getPredefinedSearchResultsApiCall(action) {
    const searchRequest = {
        pageIndex: action.pageIndex,
        pageSize: action.pageSize,
        predefinedSearchId: action.predefinedSearchId
    };

    const results = yield apiCall(getPredefinedSearchResultsApi, searchRequest);
    yield put(actions.initSearchResults(results));
}

function* fetchPredefinedSearchResults(action) {
    yield put(actions.setPagination(action.pageIndex, action.pageSize));
    yield put(actions.startFetchingSearchResults());
    yield guardedGenerator(getPredefinedSearchResultsApiCall, action);
    yield put(actions.finishFetchingSearchResults());
}

export function* watchPredefinedFetchSearchResults() {
    yield guardedTakeEvery(actionTypes.FETCH_PREDEFINED_SEARCH_RESULTS, fetchPredefinedSearchResults);
}

function* getPredefinedSearchOptionsApiCall() {
    const options = yield apiCall(getPredefinedSearchOptionsApi);
    yield put(actions.initPredefinedSearchOptions(options));
}

function* fetchPredefinedSearchOptions() {
    yield guardedGenerator(getPredefinedSearchOptionsApiCall);
}

export function* watchPredefinedFetchSearchOptions() {
    yield guardedTakeEvery(actionTypes.FETCH_PREDEFINED_SEARCH_OPTIONS, fetchPredefinedSearchOptions);
}

function* getSearchSuggestions(action) {
    const searchSuggestions = yield apiCall(getSearchSuggestionsApi, action.searchPhrase);
    yield put(actions.initSearchSuggestions(searchSuggestions));
}

function* changeSearchPhrase(action) {
    yield put(actions.startFetchingSearchSuggestions());
    yield guardedGenerator(getSearchSuggestions, action);
    yield put(actions.finishFetchingSearchSuggestions());
}

export function* watchChangeSearchPhrase() {
    yield guardedTakeEvery(actionTypes.CHANGE_SEARCH_PHRASE, changeSearchPhrase);
}
