import * as actionTypes from './actionTypes';

export const openPasswordUpdateModal = (elementId) => ({ type: actionTypes.OPEN_PASSWORD_UPDATE_MODAL, elementId });
export const closePasswordUpdateModal = () => ({ type: actionTypes.CLOSE_PASSWORD_UPDATE_MODAL });
export const togglePasswordUpdateModal = () => ({ type: actionTypes.TOGGLE_PASSWORD_UPDATE_MODAL });

export const startUpdatingPassword = () => ({ type: actionTypes.START_UPDATING_PASSWORD });
export const finishUpdatingPassword = () => ({ type: actionTypes.FINISH_UPDATING_PASSWORD });

export const updatePassword = (elementId, newPassword) => ({ type: actionTypes.UPDATE_PASSWORD, elementId, newPassword });
