import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/search/actionTypes';

export const searchReducer = (state = defaultStore.search, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    searchElements: [],
                    modifiedElements: [],
                    totalCount: 0
                }
            };
        case actionTypes.START_FETCHING_SEARCH_RESULTS:
            return {
                ...state,
                isFetching: true
            };
        case actionTypes.FINISH_FETCHING_SEARCH_RESULTS:
            return {
                ...state,
                isFetching: false
            };
        case actionTypes.INIT_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    searchElements: action.searchResults.searchElements.map((element) => {
                        const existingElement = state.searchResults.modifiedElements.find((a) => a.name === element.name);
                        if (existingElement) {
                            return { ...element, selected: existingElement.selected };
                        }

                        return element;
                    }),
                    totalCount: action.searchResults.totalCount
                }
            };
        case actionTypes.TOGGLE_SEARCH_ELEMENT_SELECTION:
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    searchElements: state.searchResults.searchElements.map((element) => {
                        if (element.name === action.elementName && element.metaType === action.metaType) {
                            return { ...element, selected: element.selected ? !element.selected : true };
                        }

                        return element;
                    }),
                    modifiedElements: [
                        ...state.searchResults.modifiedElements.filter((e) => e.name !== action.elementName),
                        {
                            ...state.searchResults.searchElements.find((e) => e.name === action.elementName),
                            selected: state.searchResults.searchElements.find((e) => e.name === action.elementName).selected
                                ? !state.searchResults.searchElements.find((e) => e.name === action.elementName).selected
                                : true
                        }
                    ]
                }
            };
        case actionTypes.INIT_SEARCH_PHRASE:
            return {
                ...state,
                searchPhrase: action.searchPhrase
            };
        case actionTypes.START_FETCHING_SEARCH_SUGGESTIONS:
            return {
                ...state,
                isFetchingSuggestions: true
            };
        case actionTypes.INIT_SEARCH_SUGGESTIONS:
            return {
                ...state,
                searchSuggestions: action.searchSuggestions
            };
        case actionTypes.FINISH_FETCHING_SEARCH_SUGGESTIONS:
            return {
                ...state,
                isFetchingSuggestions: false
            };
        case actionTypes.INIT_PREDEFINED_SEARCH_OPTIONS:
            return {
                ...state,
                predefinedSearchOptions: action.options.predefinedSearchOptions
            };
        case actionTypes.SET_PAGINATION:
            return {
                ...state,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize
            };
        default:
            return state;
    }
};
