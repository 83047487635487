import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getUserData } from 'store/user/selectors';
import AppMain from '../components/AppMain';

const mapStateToProps = (state) => ({
    isAuthenticated: getUserData(state).isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(AppMain));
