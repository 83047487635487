import * as actionTypes from './actionTypes';

export const startFetchingSearchTermData = () => ({ type: actionTypes.START_FETCHING_SEARCH_TERM });
export const finishFetchingSearchTermData = () => ({ type: actionTypes.FINISH_FETCHING_SEARCH_TERM });
export const fetchSearchTermData = (searchTerm) => ({ type: actionTypes.FETCH_SEARCH_TERM, searchTerm });
export const initSearchTermData = (data) => ({ type: actionTypes.INIT_SEARCH_TERM_DATA, data });

export const initSearchTerm = (searchTerm) => ({ type: actionTypes.INIT_SEARCH_TERM, searchTerm });

export const startFetchingAllElements = () => ({ type: actionTypes.START_FETCHING_ALL_ELEMENTS });
export const finishFetchingAllElements = () => ({ type: actionTypes.FINISH_FETCHING_ALL_ELEMENTS });
export const fetchAllElements = () => ({ type: actionTypes.FETCH_ALL_ELEMENTS });
export const initAllElements = (data) => ({ type: actionTypes.INIT_ALL_ELEMENTS, data });

export const startFetchingExport = () => ({ type: actionTypes.START_FETCHING_EXPORT });
export const finishFetchingExport = () => ({ type: actionTypes.FINISH_FETCHING_EXPORT });
export const fetchExport = (exportValues) => ({ type: actionTypes.FETCH_EXPORT, exportValues });
export const initExport = (data) => ({ type: actionTypes.INIT_EXPORT, data });

export const updateResource = (name, lang, value) => ({ type: actionTypes.UPDATE_RESOURCE, name, lang, value });
