import generator from 'generate-password';

export const getUniqueValuesFromArray = (array, byFunc) => {
    if (array === null) {
        return [];
    }
    // eslint-disable-next-line
    const objectWithUniqueKeys = array.reduce((result, next) => { result[byFunc(next)] = true; return result; }, {});
    return Object.keys(objectWithUniqueKeys);
};

export const getUniqueObjectsFromArray = (array, byFunc) => array.filter((e, i) => array.findIndex((a) => byFunc(a) === byFunc(e)) === i);

export const notEmptyOrUndefined = (value) => value !== undefined && value !== '';

export const isNullOrUndefined = (value) => value === undefined || value === null;

export const isNullOrUndefinedOrEmpty = (value) => isNullOrUndefined(value) || value === '';

export const parseFloatWithServerDelimiter = (floatString) => (floatString !== undefined && floatString !== null ? parseFloat(floatString.replace(window.clientData.numberGroupSeparator, '').replace(window.clientData.decimalSeparator, '.')) : floatString);

export const getLoginPath = (localized = false) => `/${localized ? `${window.clientData.currentLanguage}/` : ''}login?successredirect=${encodeURIComponent(window.location.pathname + window.location.search)}`;

export const getLocalizedPath = (path) => `/${window.clientData.currentLanguage}/${path}`;

export const decimalFormat = (value, precision) => {
    if (value === null || value === '') {
        return '';
    }

    return precision !== undefined ? value.toFixed(precision).replace('.', window.clientData.decimalSeparator) : value.toString().replace('.', window.clientData.decimalSeparator);
};

const formatters = [];
export const currencyFormat = (currency, currencyLocal, value) => {
    const currencyCurrencyLocal = `${currency}_${currencyLocal}`;
    let foundFormatter = formatters.find((formatter) => formatter.id === currencyCurrencyLocal);

    if (foundFormatter == null) {
        const newFormatter = { id: currencyCurrencyLocal, formatterObject: new Intl.NumberFormat(currencyLocal, { style: 'currency', currency }) };
        formatters.push(newFormatter);
        foundFormatter = newFormatter;
    }

    return foundFormatter.formatterObject.format(value).replace(currency, '');
};

export const format = (...values) => {
    let theString = values[0];

    for (let i = 1; i < values.length; i += 1) {
        const regEx = new RegExp(`\\{${i - 1}\\}`, 'gm');
        theString = theString.replace(regEx, values[i]);
    }

    return theString;
};

export const isWeekday = (date) => {
    const day = date.day();

    return day !== 0 && day !== 6;
};

export const convertStringToBool = (value) => (value
    ? value.toLowerCase() === 'true'
    : false);

export const passwordValidationRegex = () => ({ regex: /(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s)/ });

export const emailValidationRegex = () => ({ regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ });

export const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const generateRandomPassword = () => generator.generate({
    length: randomIntFromInterval(8, 24),
    symbols: true,
    lowercase: true,
    uppercase: true,
    numbers: true,
    strict: true
});
export const getStringWithReplacedSpecialChars = (value) => (value ? value.replace(/[^a-zA-Z0-9]/g, '_') : null);

export const getResourceByName = (resources, resourceName) => {
    if (resourceName) {
        if (resourceName in resources) {
            return resources[resourceName];
        }

        return resourceName;
    }

    return '';
};
