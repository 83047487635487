import * as actionTypes from './actionTypes';

export const fetchAvailableTypes = (metaTypeName) => ({ type: actionTypes.FETCH_AVAILABLE_TYPES, metaTypeName });
export const startFetchingAvailableTypes = () => ({ type: actionTypes.START_FETCHING_AVAILABLE_TYPES });
export const initAvailableTypes = (types) => ({ type: actionTypes.INIT_AVAILABLE_TYPES, types });
export const finishFetchingAvailableTypes = () => ({ type: actionTypes.FINISH_FETCHING_AVAILABLE_TYPES });
export const updateElementType = (currentElement, newElementType) => ({ type: actionTypes.UPDATE_ELEMENT_TYPE, currentElement, newElementType });

export const openChangeTypeModal = (currentElement) => ({ type: actionTypes.OPEN_CHANGE_TYPE_MODAL, currentElement });
export const closeChangeTypeModal = () => ({ type: actionTypes.CLOSE_CHANGE_TYPE_MODAL });
export const toggleChangeTypeModal = () => ({ type: actionTypes.TOGGLE_CHANGE_TYPE_MODAL });
