import { templatesEngine } from 'infrastructure/templates';

templatesEngine.registerTemplates('views.loginPage', [
    'LoginForm',
    'LoginFormInner',
    'LoginPage',
    'LoginPageInner',
    'LoginPanel'
]);

export const { LoginForm, LoginFormInner, LoginPage, LoginPageInner, LoginPanel } = templatesEngine.getTemplates('views.loginPage');
