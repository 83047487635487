import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeUser, isMetaTypeRole } from 'infrastructure/helpers/metaTypes';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getResourcesState } from 'store/layout/selectors';
import { push } from 'infrastructure/messaging/actions';
import {
    deleteElementUser,
    deleteElementRole
} from './remoteApi';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { refreshUserRoleParents } from '../sagaHelpers';

function* deleteElementInApi(action) {
    const deleteElementData = {
        elementArea: action.elementArea
    };
    if (isMetaTypeRole(action.currentElement.metaTypeName)) {
        yield apiCall(deleteElementRole, action.currentElement.id, action.deletionMethod, deleteElementData);
    }
    if (isMetaTypeUser(action.currentElement.metaTypeName)) {
        yield apiCall(deleteElementUser, action.currentElement.id, action.deletionMethod, deleteElementData);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.deleteElement_SuccessMessage));
    yield put(actions.closeDeleteElementModal());
    yield put(actions.cleanDeleteElementModal());
    yield refreshUserRoleParents(action);
    yield (put(push(`/${action.elementArea}/${action.currentElement.metaTypeName}`)));
}

function* deleteElement(action) {
    yield put(actions.startDeleteElement());
    yield guardedGenerator(deleteElementInApi, action);
    yield put(actions.finishDeleteElement());
}

export function* watchDeleteElement() {
    yield guardedTakeEvery(actionTypes.DELETE_ELEMENT, deleteElement);
}
