import * as actionTypes from './actionTypes';

export const deleteElement = (currentElement, deletionMethod, elementArea) => ({ type: actionTypes.DELETE_ELEMENT, currentElement, deletionMethod, elementArea });
export const startDeleteElement = () => ({ type: actionTypes.START_DELETING_ELEMENT });
export const finishDeleteElement = () => ({ type: actionTypes.FINISH_DELETING_ELEMENT });

export const openDeleteElementModal = (currentElement) => ({ type: actionTypes.OPEN_DELETE_ELEMENT_MODAL, currentElement });
export const closeDeleteElementModal = () => ({ type: actionTypes.CLOSE_DELETE_ELEMENT_MODAL });
export const toggleDeleteElementModal = () => ({ type: actionTypes.TOGGLE_DELETE_ELEMENT_MODAL });
export const cleanDeleteElementModal = () => ({ type: actionTypes.CLEAN_DELETE_ELEMENT_MODAL });
