import { combineReducers } from 'redux';
import * as actionTypes from 'api/languageManagement/actionTypes';
import defaultStore from 'store/defaultStore';

export const fileterReducer = (state = defaultStore.languageManagement.filter, action) => {
    switch (action.type) {
        case actionTypes.INIT_SEARCH_TERM:
            return { ...state, searchTerm: action.searchTerm };
        default:
            return state;
    }
};

export const dataReducer = (state = defaultStore.languageManagement.data, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_ALL_ELEMENTS:
            return { ...state, isFetchingElements: true };
        case actionTypes.FINISH_FETCHING_ALL_ELEMENTS:
            return { ...state, isFetchingElements: false };
        case actionTypes.INIT_ALL_ELEMENTS:
            return { ...state, data: action.data };
        case actionTypes.START_FETCHING_SEARCH_TERM:
            return { ...state, isFetchingSearchTermData: true };
        case actionTypes.FINISH_FETCHING_SEARCH_TERM:
            return { ...state, isFetchingSearchTermData: false };
        case actionTypes.INIT_SEARCH_TERM_DATA:
            return { ...state, data: action.data };
        default:
            return state;
    }
};

export const exportReducer = (state = defaultStore.languageManagement.export, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_EXPORT:
            return { ...state, isFetchingExport: true };
        case actionTypes.FINISH_FETCHING_EXPORT:
            return { ...state, isFetchingExport: false };
        case actionTypes.INIT_EXPORT:
            return { ...state, data: action.data };
        default:
            return state;
    }
};

export const languageManagementReducer = combineReducers({
    filter: fileterReducer,
    data: dataReducer,
    export: exportReducer
});
