import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeArticle, isMetaTypeCategory } from 'infrastructure/helpers/metaTypes';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getResourcesState } from 'store/layout/selectors';
import { push } from 'infrastructure/messaging/actions';
import {
    deleteElementArticle as deleteElementArticleApi,
    deleteElementCategory as deleteElementCategoryApi
} from './remoteApi';
import { deleteExportFilesFromApi } from 'api/dataManagement/remoteApi';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { refreshCatalogCmsParents } from '../sagaHelpers';

const productTreeType = 'catalog';

function* deleteElementInApi(action) {
    const deleteElementData = {
        elementArea: action.elementArea
    };
    if (action.currentElement.typeName === 'ExportDefinition') {
        yield apiCall(deleteExportFilesFromApi, action.currentElement.id)
    }
    if (isMetaTypeCategory(action.currentElement.metaTypeName)) {
        yield apiCall(deleteElementCategoryApi, action.currentElement.id, action.deletionMethod, deleteElementData);
    }
    if (isMetaTypeArticle(action.currentElement.metaTypeName)) {
        yield apiCall(deleteElementArticleApi, action.currentElement.id, action.deletionMethod, deleteElementData);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.deleteElement_SuccessMessage));
    yield put(actions.closeDeleteElementModal());
    yield put(actions.cleanDeleteElementModal());
    if (action.currentElement) {
        yield refreshCatalogCmsParents(action, productTreeType);
    }
    yield (put(push(`/${action.elementArea}/${action.currentElement.metaTypeName}`)));
}

function* deleteElement(action) {
    yield put(actions.startDeleteElement());
    yield guardedGenerator(deleteElementInApi, action);
    yield put(actions.finishDeleteElement());
}

export function* watchDeleteElement() {
    yield guardedTakeEvery(actionTypes.DELETE_ELEMENT, deleteElement);
}
