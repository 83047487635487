import * as actionTypes from 'api/configuration/actionTypes';
import defaultStore from '../defaultStore';

export const configurationReducer = (state = defaultStore.configuration, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_CONFIGURATION: {
            return { ...state, isFetching: true };
        }
        case actionTypes.INIT_CONFIGURATION: {
            return { ...state, ...action.configuration };
        }
        case actionTypes.FINISH_FETCHING_CONFIGURATION: {
            return { ...state, isFetching: false };
        }
        default: {
            return state;
        }
    }
};