import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/elementActivationOptions/actionTypes';

export const activationOptionsReducer = (state = defaultStore.userManagement.activationOptions, action) => {
    switch (action.type) {
        case actionTypes.START_UPDATING_ACTIVATION_OPTIONS:
            return { ...state, isUpdatingActivationOptions: true };
        case actionTypes.FINISH_UPDATING_ACTIVATION_OPTIONS:
            return { ...state, isUpdatingActivationOptions: false };
        case actionTypes.OPEN_UPDATING_ACTIVATION_OPTIONS_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement }, openingSource: action.openingSource };
        case actionTypes.CLOSE_UPDATING_ACTIVATION_OPTIONS_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_UPDATING_ACTIVATION_OPTIONS_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement,
                openingSource: state.isModalOpen ? null : state.openingSource,
            };
        case actionTypes.CLEAN_UPDATING_ACTIVATION_OPTIONS_MODAL:
            return {
                ...state,
                currentElement: null,
                openingSource: ''
            };
        default:
            return state;
    }
};
