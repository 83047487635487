export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const START_FETCHING_SEARCH_RESULTS = 'START_FETCHING_SEARCH_RESULTS';
export const FINISH_FETCHING_SEARCH_RESULTS = 'FINISH_FETCHING_SEARCH_RESULTS';
export const INIT_SEARCH_RESULTS = 'INIT_SEARCH_RESULTS';
export const TOGGLE_SEARCH_ELEMENT_SELECTION = 'TOGGLE_SEARCH_ELEMENT_SELECTION';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CHANGE_SEARCH_PHRASE = 'CHANGE_SEARCH_PHRASE';
export const INIT_SEARCH_PHRASE = 'INIT_SEARCH_PHRASE';
export const START_FETCHING_SEARCH_SUGGESTIONS = 'START_FETCHING_SEARCH_SUGGESTIONS';
export const INIT_SEARCH_SUGGESTIONS = 'INIT_SEARCH_SUGGESTIONS';
export const FINISH_FETCHING_SEARCH_SUGGESTIONS = 'FINISH_FETCHING_SEARCH_SUGGESTIONS';
export const FETCH_PREDEFINED_SEARCH_RESULTS = 'FETCH_PREDEFINED_SEARCH_RESULTS';
export const FETCH_PREDEFINED_SEARCH_OPTIONS = 'FETCH_PREDEFINED_SEARCH_OPTIONS';
export const INIT_PREDEFINED_SEARCH_OPTIONS = 'INIT_PREDEFINED_SEARCH_OPTIONS';
export const SET_PAGINATION = 'SET_PAGINATION';
