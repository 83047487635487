import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { push } from 'infrastructure/messaging/actions';
import { getTreeConfig } from 'store/userManagement/userManagementTree/selectors';
import {
    fetchAvailableTypesForParentMetaType as fetchAvailableTypesForParentMetaTypeApi,
    createNewElement as createNewElementApi
} from './remoteApi';
import * as actions from './actions';
import { refreshTreeElement } from '../userManagementTree/actions';
import * as actionTypes from './actionTypes';

function* fetchAvailableTypesForParentMetaTypeFromApi(action) {
    const types = yield apiCall(fetchAvailableTypesForParentMetaTypeApi, action.metaTypeName);
    return types;
}

function* fetchAvailableTypesForParentMetaType(action) {
    yield put(actions.startFetchingAvailableTypesForParentMetaType());
    const types = yield guardedGenerator(fetchAvailableTypesForParentMetaTypeFromApi, action);
    yield put(actions.initAvailableTypesForParentMetaType(types));
    yield put(actions.finishFetchingAvailableTypesForParentMetaType());
}

export function* watchFetchAvailableTypesForParentMetaType() {
    yield guardedTakeEvery(actionTypes.FETCH_AVAILABLE_TYPES_FOR_PARENT_METATYPE, fetchAvailableTypesForParentMetaType);
}

function* createNewElementInApi(action) {
    const parentData = action.currentElement
        ? {
            parentElementId: action.currentElement.id,
            parentMetaTypeName: action.currentElement.metaTypeName
        }
        : {};
    const { elementArea } = action;
    const newElementData = {
        ...action.newElementData,
        ...parentData,
        elementArea
    };
    yield apiCall(createNewElementApi, newElementData);
    yield put(actions.closeNewElementModal());
    yield put(actions.cleanNewElementModal());
    if (Object.keys(action.currentElement).length !== 0) {
        yield put(refreshTreeElement(
            action.currentElement.id,
            action.currentElement.metaTypeName
        ));
    } else {
        const rootConfig = yield select(getTreeConfig);
        yield put(refreshTreeElement(rootConfig.userManagementTreeConfiguration.topElementName, 'Role'));
    }

    yield (put(push(`/user-management/${action.newElementData.metaTypeName}/${action.newElementData.id}`)));
}

function* createNewElement(action) {
    yield put(actions.startCreatingNewElement());
    yield guardedGenerator(createNewElementInApi, action);
    yield put(actions.finishCreatingNewElement());
}

export function* watchCreateNewElement() {
    yield guardedTakeEvery(actionTypes.CREATE_NEW_ELEMENT, createNewElement);
}
