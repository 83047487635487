import { put } from 'redux-saga/effects';
import { push } from 'infrastructure/messaging/actions';
import * as actions from 'api/catalog/exportElement/actions';
import * as actionTypes from 'api/catalog/exportElement/actionTypes';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { fetchAvailableExportDefinitionsApi, createNewExportJob, fetchExportDefinitionInitialOptionsApi, fetchMetaTypeAvailablePropertiesApi, addNewExportDefinitionApi } from 'api/catalog/exportElement/remoteApi';

function* fetchAvailableExportDefinitionsFromApi() {
    const exportDefinitions = yield apiCall(fetchAvailableExportDefinitionsApi);
    return exportDefinitions;
}

function* fetchAvailableExportDefinitions(action) {
    const exportDefinitions = yield guardedGenerator(fetchAvailableExportDefinitionsFromApi, action);
    yield put(actions.initAvailableExportDefinitions(exportDefinitions));
}

export function* watchFetchAvailableExportDefinitions() {
    yield guardedTakeEvery(actionTypes.FETCH_AVAILABLE_EXPORT_DEFINITIONS, fetchAvailableExportDefinitions);
}

function* addNewExportJobProcess(action) {
    const addNewExportJobBody = {
        elementName: action.currentElement,
        exportDefinition: action.exportDefinition,
        elementMetaTypeName: action.elementMetaType
    };
    yield apiCall(createNewExportJob, addNewExportJobBody);
    yield put(actions.closeExportElementModal());
    yield (put(push('/data-management/export')));
}

function* addNewExportJob(action) {
    yield put(actions.startAddingNewExportJob());
    yield guardedGenerator(addNewExportJobProcess, action);
    yield put(actions.finishAddingNewExportJob());
}

export function* watchAddNewExportJob() {
    yield guardedTakeEvery(actionTypes.ADD_NEW_EXPORT_JOB, addNewExportJob);
}

function* fetchExportDefinitionInitialOptionsFromApi() {
    const initialOptions = yield apiCall(fetchExportDefinitionInitialOptionsApi);
    return initialOptions;
}

function* fetchExportDefinitionInitialOptions() {
    const initialOptions = yield guardedGenerator(fetchExportDefinitionInitialOptionsFromApi);
    yield put(actions.initExportDefinitionInitialOptions(initialOptions));
}

export function* watchFetchExportDefinitionInitialOptions() {
    yield guardedTakeEvery(actionTypes.FETCH_EXPORT_DEFINITION_INITIAL_OPTIONS, fetchExportDefinitionInitialOptions);
}

function* fetchMetaTypeProperties(action) {
    const fetchedInitialOptions = yield apiCall(fetchMetaTypeAvailablePropertiesApi, action.selection);
    yield put(actions.initExportDefinitionAvailableProperties(fetchedInitialOptions));
}

export function* watchFetchMetaTypeProperties() {
    yield guardedTakeEvery(actionTypes.FETCH_META_TYPE_PROPERTIES, fetchMetaTypeProperties);
}

export function* addNewExportDefinition(action)
{
        const addNewExportDefinitionBody = {
        exportDefinitionName: action.exportDefinitionName,
        exportMetaTypes: action.exportMetaTypes,
        exportLanguages: action.exportLanguages,
        exportDefinitionDepth: action.exportDefinitionDepth,
        selectedProperties: action.selectedProperties
    };
    yield apiCall(addNewExportDefinitionApi, addNewExportDefinitionBody);
    yield put(actions.fetchAvailableExportDefinitions());
    yield put(actions.closeExportDefinitionModal());
}

export function* watchAddNewExportDefinition() {
    yield guardedTakeEvery(actionTypes.ADD_NEW_EXPORT_DEFINITION, addNewExportDefinition);
}
