import * as actionTypes from './actionTypes';

export const fetchTreeElements = (data, rootKey) => ({ type: actionTypes.FETCH_TREE_ELEMENTS, data, rootKey });
export const startFetchingTreeElements = (currentNode) => ({ type: actionTypes.START_FETCHING_TREE_ELEMENTS, currentNode });
export const finishFetchingTreeElements = (currentNode) => ({ type: actionTypes.FINISH_FETCHING_TREE_ELEMENTS, currentNode });
export const initTreeElements = (elements, data, rootKey) => ({ type: actionTypes.INIT_TREE_ELEMENTS, elements, data, rootKey });
export const expandTreeElement = (elements, currentNode, needsAddingNewElements) => ({ type: actionTypes.EXPAND_TREE_ELEMENT, elements, currentNode, needsAddingNewElements });
export const collapseTreeElement = (currentNode) => ({ type: actionTypes.COLLAPSE_TREE_ELEMENT, currentNode });

export const refreshTreeElement = (nodeName, nodeType) => ({ type: actionTypes.REFRESH_TREE_ELEMENT, nodeName, nodeType });
export const startRefreshingElements = () => ({ type: actionTypes.START_REFRESHING_TREE_ELEMENTS });
export const finishRefreshingElements = () => ({ type: actionTypes.FINISH_REFRESHING_TREE_ELEMENTS });
export const initRefreshingTreeElements = (elements, data) => ({ type: actionTypes.INIT_REFRESHING_TREE_ELEMENTS, elements, data });
export const changeElementIdInTree = (currentElementId, newElementId) => ({ type: actionTypes.CHANGE_ELEMENT_ID_IN_TREE, currentElementId, newElementId });

export const fetchTreeConfig = () => ({ type: actionTypes.FETCH_TREE_CONFIG });
export const startFetchingTreeConfig = () => ({ type: actionTypes.START_FETCHING_TREE_CONFIG });
export const finishFetchingTreeConfig = () => ({ type: actionTypes.FINISH_FETCHING_TREE_CONFIG });
export const initTreeConfig = (config) => ({ type: actionTypes.INIT_TREE_CONFIG, config });
