import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reduxCatch from 'redux-catch';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { errorHandler } from '../infrastructure/errorHandling';

export default function configureStore(history) {
    const sagaMiddleware = createSagaMiddleware();
    const router = routerMiddleware(history);
    let developmentStoreEnhancer;

    // eslint-disable-next-line no-underscore-dangle
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        developmentStoreEnhancer = compose(
            applyMiddleware(sagaMiddleware, logger, router, reduxCatch(errorHandler)),
            // eslint-disable-next-line no-underscore-dangle
            window.__REDUX_DEVTOOLS_EXTENSION__()
        );
    } else {
        developmentStoreEnhancer = compose(applyMiddleware(sagaMiddleware, logger, router, reduxCatch(errorHandler)));
    }

    const productionStoreEnhancer = applyMiddleware(sagaMiddleware, router);

    const storeEnhancer = process.env.NODE_ENV === 'production' ? productionStoreEnhancer : developmentStoreEnhancer;
    const store = createStore(rootReducer(history), undefined, storeEnhancer);

    sagaMiddleware.run(rootSaga);

    return store;
}
