import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
const withResources = (Child) => class extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static displayName = `withResources(${Child.displayName})`;

    // eslint-disable-next-line react/static-property-placement
    static contextTypes = {
        resources: PropTypes.shape({})
    };

    render() {
        return <Child {...this.props} {...this.context} />;
    }
};

export default withResources;
