import { http } from 'infrastructure/http';

export const deleteElementArticle = (articleName, deletionMethod = 'SingleElement', deleteElementData) => {
    const uri = `/api/catalog/elements/article/${articleName}/${deletionMethod}`;
    return http.delete(uri, deleteElementData);
};

export const deleteElementCategory = (categoryName, deletionMethod = 'SingleElement', deleteElementData) => {
    const uri = `/api/catalog/elements/category/${categoryName}/${deletionMethod}`;
    return http.delete(uri, deleteElementData);
};
