import * as actionTypes from './actionTypes';

export const changeUserEmail = (currentElement, newUserEmail, elementArea) => ({ type: actionTypes.CHANGE_USER_EMAIL, currentElement, newUserEmail, elementArea });
export const startChangingUserEmail = () => ({ type: actionTypes.START_CHANGING_USER_EMAIL });
export const finishChangingUserEmail = () => ({ type: actionTypes.FINISH_CHANGING_USER_EMAIL });

export const openChangeUserEmailModal = (currentElement) => ({ type: actionTypes.OPEN_CHANGE_USER_EMAIL_MODAL, currentElement });
export const closeChangeUserEmailModal = () => ({ type: actionTypes.CLOSE_CHANGE_USER_EMAIL_MODAL });
export const toggleChangeUserEmailModal = () => ({ type: actionTypes.TOGGLE_CHANGE_USER_EMAIL_MODAL });
export const cleanChangeUserEmailModal = () => ({ type: actionTypes.CLEAN_CHANGE_USER_EMAIL_MODAL });
