export const getIsFetchingState = (state) => state.cms.cmsTree.isFetching;
export const getIsFetchingWholeTreeState = (state) => state.cms.cmsTree.isFetchingWholeTree;
export const getCmsTreeState = (state) => state.cms.cmsTree.tree;
export const getCmsTreeConfig = (state) => state.cms.cmsTree.config;
export const getThreeDotsActions = (treeConfig, elementType, elementMetaType) => {
    let foundAction = treeConfig.actions.find((a) => a.elementType.toLowerCase() === elementType.toLowerCase()
        && a.elementMetaType.toLowerCase() === elementMetaType.toLowerCase());

    if (!foundAction) {
        foundAction = treeConfig.actions.find((a) => a.elementType.toLowerCase() === 'default'
            && a.elementMetaType.toLowerCase() === 'default');
    }

    return foundAction.actionPlacements;
};
export const getElementParents = (state, elementId) => state.cms.cmsTree.tree
    .filter((x) => x.nodeName === elementId)
    .map((x) => ({ parentName: x.parentName, parentMetaTypeName: x.parentMetaTypeName }));
