import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import { AppMain } from 'general/AppMain';

import ResizeDetector from 'react-resize-detector';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };
    }

    render() {
        const {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        return (
            <>
                <ResizeDetector
                    handleWidth
                    render={({ width }) => (
                        <>
                            <div className={cx(
                                `app-container app-theme-${colorScheme}`,
                                { 'fixed-header': enableFixedHeader },
                                { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                                { 'fixed-footer': enableFixedFooter },
                                { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                                { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                                { 'sidebar-mobile-open': enableMobileMenu },
                                { 'body-tabs-shadow-btn': enablePageTabsAlt },
                            )}>
                                <AppMain />
                            </div>
                        </>
                    )}
                />
            </>
        );
    }
}

const mapStateToProp = (state) => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

}); export default withRouter(connect(mapStateToProp)(Main));
