import { raiseError } from './actions';
import { GENERAL } from './errorCodes';

export default (error, dispatch) => {
    if (window.console) {
        window.console.log(error);
    }

    dispatch(raiseError(GENERAL));
};
