import { combineReducers } from 'redux';
import { userManagementTreeReducer } from './userManagementTree/reducers';
import { newElementReducer } from './newElement/reducers';
import { elementReducer, elementTypeConfigurationReducer } from './element/reducers';
import { deleteElementReducer } from './deleteElement/reducers';
import { copyElementReducer } from './copyElement/reducers';
import { changeElementTypeReducer } from './changeElementType/reducers';
import { changeElementIdReducer } from './changeElementId/reducers';
import { changeUserEmailReducer } from './changeUserEmail/reducers';
import { passwordUpdateReducer } from './passwordUpdate/reducers';
import { activationOptionsReducer } from './elementActivationOptions/reducers';

export const userManagementReducer = combineReducers({
    element: elementReducer,
    elementTypeConfiguration: elementTypeConfigurationReducer,
    userManagementTree: userManagementTreeReducer,
    newElement: newElementReducer,
    deleteElement: deleteElementReducer,
    copyElement: copyElementReducer,
    changeElementType: changeElementTypeReducer,
    changeElementId: changeElementIdReducer,
    changeUserEmail: changeUserEmailReducer,
    passwordUpdate: passwordUpdateReducer,
    activationOptions: activationOptionsReducer,
});
