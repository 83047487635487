import React, { Fragment } from 'react';

const templates = {

};

const components = {

};

export const defaultTemplate = {};

/* eslint-disable */ 
const createTemplate = (key, componentName) => class extends React.Component {
    render() {
        const Component = components[key][componentName];
        if (!Component)
        {
            return 'Invalid component';
        }

        if (Array.isArray(Component)) {
            return (
                <Fragment>
                    {Component.map((ArrayComponent, index) => {
                        if (typeof ArrayComponent === "object") {
                            const { component: Component, ...defaultProps } = ArrayComponent;
                            return (<Component {...defaultProps} {...this.props} key={ArrayComponent.name + index} />);
                        }

                        return (<ArrayComponent {...this.props} key={ArrayComponent.name + index} />);
                    })}
                </Fragment>
            );
        }

        if (typeof Component === "object") {
            const { component: InnerComponent, ...defaultProps } = Component;
            return <InnerComponent {...defaultProps} {...this.props} />
        }
        
        return (<Component {...this.props} />);
    }
};
/* eslint-enable */

const prepareTemplates = (key, descriptors) => {
    const preparedTemplates = descriptors.reduce((acc, entry) => {
        acc[entry] = createTemplate(key, entry);
        return acc;
    }, {});

    return preparedTemplates;
};

const mergeComponentsWithOverrides = (defaultComponents, overrides) => {
    let mergedComponents = { ...defaultComponents, ...overrides };
    mergedComponents = Object.entries(mergedComponents)
        .reduce((acc, entry) => {
            const componentName = entry[0];
            let registeredImplementations = entry[1];
            registeredImplementations = Array.isArray(registeredImplementations)
                ? registeredImplementations.map((impl) => (impl === defaultTemplate ? defaultComponents[componentName] : impl))
                : registeredImplementations;
            acc[entry[0]] = registeredImplementations;
            return acc;
        }, {});

    return mergedComponents;
};

export const registerTemplates = (key, descriptors) => {
    if (!templates[key]) {
        templates[key] = prepareTemplates(key, descriptors);
    }
};

export const getTemplates = (key) => templates[key];

export const registerDefaultComponents = (key, defaultComponents) => {
    components[key] = mergeComponentsWithOverrides(defaultComponents, components[key]);
};

export const registerComponentsOverrides = (key, overrides) => {
    components[key] = mergeComponentsWithOverrides(components[key], overrides);
};

export const dynamicRegisterComponents = (key, component, descriptors) => {
    components[key] = mergeComponentsWithOverrides(component, components[key]);
    const templatesNew = prepareTemplates(key, descriptors);
    Object.keys(templatesNew).forEach((t) => { templates[key][t] = templatesNew[t]; });
};
