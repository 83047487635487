import { combineReducers } from 'redux';
import * as actionTypes from 'api/media/actionTypes';
import defaultStore from '../defaultStore';

export const elementReducer = (state = defaultStore.media.element, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_MEDIA_DATA:
            return { ...state, isFetchingData: true };
        case actionTypes.FINISH_FETCHING_MEDIA_DATA:
            return { ...state, isFetchingData: false };
        case actionTypes.START_SAVING_MEDIA_DATA:
            return { ...state, isSavingData: true };
        case actionTypes.FINISH_SAVING_MEDIA_DATA:
            return { ...state, isSavingData: false };
        case actionTypes.INIT_MEDIA_DATA:
            return { ...state, elementData: action.elementData };
        case actionTypes.START_FETCHING_ATTACHMENT_ASSOCIATIONS:
            return { ...state, isFetchingAssociations: true };
        case actionTypes.FINISH_FETCHING_ATTACHMENT_ASSOCIATIONS:
            return { ...state, isFetchingAssociations: false };
        case actionTypes.INIT_ATTACHMENT_ASSOCIATIONS:
            return { ...state, attachmentAssociations: action.attachmentAssociations };
        default:
            return state;
    }
};

export const elementTypeConfigurationReducer = (state = defaultStore.media.elementTypeConfiguration, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_MEDIA_CONFIGURATION:
            return { ...state, isFetchingConfigurationData: true };
        case actionTypes.FINISH_FETCHING_MEDIA_CONFIGURATION:
            return { ...state, isFetchingConfigurationData: false };
        case actionTypes.INIT_MEDIA_CONFIGURATION:
            return { ...state, elementConfigurationData: action.mediaConfigurationData };
        default:
            return state;
    }
};

export const elementsReducer = (state = defaultStore.media.elements, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_MEDIA_ELEMENTS:
            return { ...state, isFetching: true };
        case actionTypes.INIT_MEDIA_ELEMENTS:
            if (state.pageIndex > 0) {
                return {
                    ...state,
                    elementsData: {
                        totalCount: action.elements.totalCount,
                        medias: action.isPrependingMedia ? [...state.elementsData.medias, ...action.elements.medias] : [...action.elements.medias],
                    },
                };
            }

            return { ...state, elementsData: action.elements };
        case actionTypes.TOGGLE_MEDIA_ELEMENT_SELECTION:
            return {
                ...state,
                elementsData: {
                    ...state.elementsData,
                    medias: state.elementsData.medias.map((mediaElement) => {
                        if (mediaElement.name === action.elementName) {
                            return { ...mediaElement, selected: !mediaElement.selected };
                        }

                        return action.selectionMode === 'single' ? { ...mediaElement, selected: false } : mediaElement;
                    }),
                },
            };
        case actionTypes.LOAD_MORE_MEDIA:
            return { ...state, pageIndex: state.pageIndex + 1 };
        case actionTypes.INIT_FILTERS:
            return { ...state, pageIndex: 0 };
        case actionTypes.FINISH_FETCHING_MEDIA_ELEMENTS: {
            return { ...state, isFetching: false };
        }
        case actionTypes.DELETE_MEDIA_ELEMENTS:
            return { ...state, fileNames: action.fileNames };
        default:
            return state;
    }
};

export const filterReducer = (state = defaultStore.media.filter, action) => {
    switch (action.type) {
        case actionTypes.INIT_FILTERS:
            return { ...state, searchTerm: action.searchTerm, filterExtensions: action.filterExtensions };
        default:
            return state;
    }
};

export const uploadFilesReducer = (state = defaultStore.media.uploadFiles, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_FILE_NAMES_INFO:
            return { ...state, isFetchingNamesInfo: true };
        case actionTypes.FINISH_FETCHING_FILE_NAMES_INFO:
            return { ...state, isFetchingNamesInfo: false };
        case actionTypes.INIT_FILE_NAMES_INFO:
            return { ...state, namesInfo: action.fileNamesInfo };
        default:
            return state;
    }
};

export const newMediasReducer = (state = defaultStore.media.newMedias, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_MEDIA_INFOS:
            return { ...state, isFetching: true };
        case actionTypes.FINISH_FETCHING_MEDIA_INFOS:
            return { ...state, isFetching: false };
        case actionTypes.INIT_MEDIA_INFOS:
            return { ...state, mediaInfos: action.mediaInfos };
        default:
            return state;
    }
};

export const mediaExtensionsReducer = (state = defaultStore.media.extensions, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_SUPPORTED_EXTENSIONS:
            return { ...state, isFetchingSupportedExtensions: true };
        case actionTypes.FINISH_FETCHING_SUPPORTED_EXTENSIONS:
            return { ...state, isFetchingSupportedExtensions: false };
        case actionTypes.INIT_SUPPORTED_EXTENSIONS:
            return { ...state, supportedExtensions: action.supportedExtensions };
        default:
            return state;
    }
};

export const mediaReducer = combineReducers({
    elements: elementsReducer,
    element: elementReducer,
    elementTypeConfiguration: elementTypeConfigurationReducer,
    filter: filterReducer,
    uploadFiles: uploadFilesReducer,
    newMedias: newMediasReducer,
    extensions: mediaExtensionsReducer
});
