import Http from 'infrastructure/http/http';
import { http } from 'infrastructure/http';

export const getMediaApi = (data) => Http.get('/api/media', data);

export const getFileNamesInfoApi = (fileNames) => Http.post('/api/media/filesExist', fileNames);

export const getMediaDataApi = (elementId) => http.get(`api/media/elements/${elementId}`);
export const getMediaConfigurationApi = (elementType) => http.get(`/api/media/element-type/${elementType}`);
export const saveMediaDataApi = (elementId, formData) => http.put(`/api/media/elements/${elementId}`, formData);

export const deleteMediaElementsApi = (fileNames) => Http.delete('/api/media/deleteFiles', fileNames);

export const getMediaInfosApi = (data) => Http.get('/api/media/infos', data);

export const getAttachmentAssociationsApi = (elementId) => Http.get(`/api/media/elements/${elementId}/associations`);

export const getSupportedExtensionsApi = () => Http.get('/api/media/supportedExtensions');
