import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/catalog/copyElement/actionTypes';

export const copyElementReducer = (state = defaultStore.catalog.copyElement, action) => {
    switch (action.type) {
        case actionTypes.START_COPYING_ELEMENT:
            return { ...state, isCopyingElement: true };
        case actionTypes.FINISH_COPYING_ELEMENT:
            return { ...state, isCopyingElement: false };
        case actionTypes.OPEN_COPY_ELEMENT_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_COPY_ELEMENT_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_COPY_ELEMENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_COPY_ELEMENT_MODAL:
            return {
                ...state,
                currentElement: null,
                copyOption: { ...action.copyOption }
            };
        default:
            return state;
    }
};
