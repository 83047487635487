import { put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall } from 'infrastructure/helpers/sagasHelper';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getConfigurationApi } from './remoteApi';

function* getConfiguration() {
    const configuration = yield apiCall(getConfigurationApi);
    yield put(actions.initConfiguration(configuration));
}

function* fetchConfiguration() {
    yield put(actions.startFetchingConfiguration());
    yield getConfiguration();
    yield put(actions.finishFetchingConfiguration());
}

export function* watchFetchConfiguration() {
    yield guardedTakeEvery(actionTypes.FETCH_CONFIGURATION, fetchConfiguration);
}
