import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { getTreeState } from 'store/userManagement/userManagementTree/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getTreeElementsApi, fetchTreeConfigApi } from './remoteApi';

function* getTreeElements(action) {
    const tree = yield select(getTreeState);
    let elements = [];

    const path = action.data && action.data.nodePath ? action.data.nodePath : null;

    if (action.data && action.data.nodePath && tree.findIndex((e) => e.id === action.data.nodePath) >= 0) {
        if (action.data.expanded) {
            if (tree.find((e) => e.parent === path && e.id === `stubId${action.data.nodePath}`)) {
                elements = yield apiCall(getTreeElementsApi, action.data);
                yield put(actions.expandTreeElement(elements, action.data, true));
            } else {
                yield put(actions.expandTreeElement(elements, action.data, false));
            }
        } else {
            yield put(actions.collapseTreeElement(action.data));
        }
    }

    if ((path === null && tree && tree.length === 0) || (action.data && action.data.refresh)) {
        elements = yield apiCall(getTreeElementsApi, action.data);
        yield put(actions.initTreeElements(elements, action.data, action.rootKey));
    }
}

function* fetchTreeElements(action) {
    yield put(actions.startFetchingTreeElements(action.data));
    yield getTreeElements(action);
    yield put(actions.finishFetchingTreeElements(action.data));
}

export function* watchFetchTreeElements() {
    yield guardedTakeEvery(actionTypes.FETCH_TREE_ELEMENTS, fetchTreeElements);
}

function* getRefreshedTree(action) {
    const elements = yield apiCall(getTreeElementsApi, action);
    yield put(actions.initRefreshingTreeElements(elements, action));
}

function* refreshTreeElements(action) {
    yield put(actions.startFetchingTreeElements(action.data));
    yield getRefreshedTree(action);
    yield put(actions.finishFetchingTreeElements(action.data));
}

export function* watchRefreshTreeElements() {
    yield guardedTakeEvery(actionTypes.REFRESH_TREE_ELEMENT, refreshTreeElements);
}

function* fetchTreeConfigFromApi() {
    const config = yield apiCall(fetchTreeConfigApi);
    yield put(actions.initTreeConfig(config));
}

function* fetchTreeConfig(action) {
    yield put(actions.startFetchingTreeConfig());
    yield guardedGenerator(fetchTreeConfigFromApi, action);
    yield put(actions.finishFetchingTreeConfig());
}

export function* watchFetchTreeConfig() {
    yield guardedTakeEvery(actionTypes.FETCH_TREE_CONFIG, fetchTreeConfig);
}
