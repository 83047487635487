import { put, select } from 'redux-saga/effects';
import { getElementParents } from 'store/userManagement/userManagementTree/selectors';
import { refreshTreeElement } from './userManagementTree/actions';

export function* refreshUserRoleParents(action) {
    const elementParents = yield select(getElementParents, action.currentElement.id);
    if (action.currentElement) {
        // eslint-disable-next-line no-restricted-syntax
        for (const elementParent of elementParents) {
            yield put(refreshTreeElement(
                elementParent.parentName,
                elementParent.parentMetaTypeName
            ));
        }
    }
}
