import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeUser, isMetaTypeRole } from 'infrastructure/helpers/metaTypes';
import { getResourcesState } from 'store/layout/selectors';
import { getElementDataApi } from 'api/userManagement/element/remoteApi';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import {
    fetchAvailableTypesApi,
    updateUserTypeApi,
    updateRoleTypeApi
} from './remoteApi';
import { initElementData } from '../element/actions';

function* fetchAvailableTypesFromApi(action) {
    const types = yield apiCall(fetchAvailableTypesApi, action.metaTypeName);
    return types;
}

function* fetchAvailableTypes(action) {
    const types = yield guardedGenerator(fetchAvailableTypesFromApi, action);
    yield put(actions.initAvailableTypes(types));
}

export function* watchFetchAvailableTypes() {
    yield guardedTakeEvery(actionTypes.FETCH_AVAILABLE_TYPES, fetchAvailableTypes);
}

function* updateElementTypeInApi(action) {
    const changeElementTypeRequestBody = {
        newElementTypeId: action.newElementType,
    };
    if (isMetaTypeRole(action.currentElement.metaTypeName)) {
        yield apiCall(updateRoleTypeApi, action.currentElement.id, changeElementTypeRequestBody);
    }
    if (isMetaTypeUser(action.currentElement.metaTypeName)) {
        yield apiCall(updateUserTypeApi, action.currentElement.id, changeElementTypeRequestBody);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.changeElementType_SuccessMessage));
    yield put(actions.closeChangeTypeModal());
    const elementData = yield apiCall(getElementDataApi, action.currentElement.id, action.currentElement.metaTypeName);
    yield put(initElementData(elementData));
}

function* updateElementType(action) {
    yield guardedGenerator(updateElementTypeInApi, action);
}

export function* watchUpdateElementType() {
    yield guardedTakeEvery(actionTypes.UPDATE_ELEMENT_TYPE, updateElementType);
}
