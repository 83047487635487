export const FETCH_TREE_ELEMENTS = 'FETCH_TREE_ELEMENTS';
export const START_FETCHING_TREE_ELEMENTS = 'START_FETCHING_TREE_ELEMENTS';
export const FINISH_FETCHING_TREE_ELEMENTS = 'FINISH_FETCHING_TREE_ELEMENTS';
export const INIT_TREE_ELEMENTS = 'INIT_TREE_ELEMENTS';
export const EXPAND_TREE_ELEMENT = 'EXPAND_TREE_ELEMENT';
export const COLLAPSE_TREE_ELEMENT = 'COLLAPSE_TREE_ELEMENT';
export const REFRESH_TREE_ELEMENT = 'REFRESH_TREE_ELEMENT';
export const START_REFRESHING_TREE_ELEMENTS = 'START_REFRESHING_TREE_ELEMENTS';
export const FINISH_REFRESHING_TREE_ELEMENTS = 'FINISH_REFRESHING_TREE_ELEMENTS';
export const INIT_REFRESHING_TREE_ELEMENTS = 'INIT_REFRESHING_TREE_ELEMENTS';
export const CHANGE_ELEMENT_ID_IN_TREE = 'CHANGE_ELEMENT_ID_IN_TREE';

export const FETCH_TREE_CONFIG = 'FETCH_TREE_CONFIG';
export const START_FETCHING_TREE_CONFIG = 'START_FETCHING_TREE_CONFIG';
export const FINISH_FETCHING_TREE_CONFIG = 'FINISH_FETCHING_TREE_CONFIG';
export const INIT_TREE_CONFIG = 'INIT_TREE_CONFIG';
