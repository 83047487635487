import jwtDecode from 'jwt-decode';

const AUTHTOKEN_STORAGE_NAME = 'authToken';
const LASTCALL_STORAGE_NAME = 'lastCall';

const NAME_CLAIM_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
const EMAIL_CLAIM_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
const FIRSTNAME_CLAIM_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
const LASTNAME_CLAIM_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
const REQUIRES_PASSWORD_CHANGE_CLAIM_NAME = 'requiresPasswordChange';
const USER_LANGUAGE_CLAIM_NAME = 'userLanguage';

const toMinutes = (miliseconds) => miliseconds / (1000 * 60);

const setLastCall = (dateTime) => localStorage.setItem(LASTCALL_STORAGE_NAME, dateTime);

const removeLastCall = () => localStorage.removeItem(LASTCALL_STORAGE_NAME);

const getLastCall = () => {
    const storedLastCall = localStorage.getItem(LASTCALL_STORAGE_NAME);
    if (storedLastCall) {
        return new Date(storedLastCall);
    }

    return undefined;
};

const setToken = (token) => localStorage.setItem(AUTHTOKEN_STORAGE_NAME, token);

const removeToken = () => localStorage.removeItem(AUTHTOKEN_STORAGE_NAME);

const getToken = () => localStorage.getItem(AUTHTOKEN_STORAGE_NAME);

const sessionExpired = () => {
    const jwtToken = getToken();
    const lastCallDate = getLastCall();

    // checking session expiration only makes sense if user was previousely logged in (token is provided)
    if (jwtToken && lastCallDate) {
        const currentSessionLength = toMinutes(new Date() - lastCallDate);
        return currentSessionLength > window.clientData.sessionLength;
    }

    return false;
};

const shouldRefreshToken = () => {
    const jwtToken = getToken();
    const lastCallDate = getLastCall();

    if (!jwtToken || !lastCallDate) {
        return false;
    }

    const jwt = jwtDecode(jwtToken);
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(jwt.exp);
    const minutesToExpiration = toMinutes(expirationDate - new Date());

    return !sessionExpired() && minutesToExpiration > 0 && minutesToExpiration < window.clientData.sessionLength;
};

const isAuthenticated = () => {
    const jwtToken = getToken();

    if (!jwtToken) {
        return false;
    }

    const jwt = jwtDecode(jwtToken);
    const expirationDate = new Date(0);
    expirationDate.setUTCSeconds(jwt.exp);
    return expirationDate > new Date();
};

const getUserTokenData = () => {
    const jwtToken = getToken();

    if (!jwtToken) {
        return undefined;
    }

    const jwt = jwtDecode(jwtToken);
    return {
        userName: jwt[NAME_CLAIM_NAME],
        email: jwt[EMAIL_CLAIM_NAME],
        firstName: jwt[FIRSTNAME_CLAIM_NAME],
        lastName: jwt[LASTNAME_CLAIM_NAME],
        requiresPasswordChange: jwt[REQUIRES_PASSWORD_CHANGE_CLAIM_NAME] === 'true',
        userLanguage: jwt[USER_LANGUAGE_CLAIM_NAME]
    };
};

export const authenticationContext = {
    isAuthenticated,
    setToken,
    removeToken,
    getToken,
    shouldRefreshToken,
    setLastCall,
    removeLastCall,
    getLastCall,
    getUserTokenData,
    sessionExpired
};
