import { combineReducers } from 'redux';
import defaultStore from 'store/defaultStore';
import { SET_USER_DATA, CLEAR_USER_DATA } from 'api/user/login/actionTypes';
import { loginReducer } from './loginReducer';

const userDataReducer = (state = defaultStore.user.data, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...action.data };
        case CLEAR_USER_DATA:
            return {
                isAuthenticated: false
            };
        default:
            return state;
    }
};

export const userReducer = combineReducers({
    data: userDataReducer,
    login: loginReducer,
});
