import React from 'react';
import { Row, Col } from 'reactstrap';
import { shape, string } from 'prop-types';
import LoginForm from '../containers/LoginForm';

const LoginPanel = ({ location, resources }) => {
    const params = new URLSearchParams(location.search.toLowerCase());
    const redirectUrl = params.get('successredirect');

    return (
        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
            <div className="app-logo" />
            <h1 className="mb-0">{resources.welcomeText}</h1>
            <p>{resources.signInRequestText}</p>
            <Row className="divider" />
            <LoginForm redirectUrl={redirectUrl} />
        </Col>
    );
};

LoginPanel.propTypes = {
    resources: shape({
        welcomeText: string,
        signInRequestText: string,
        singUpText: string
    }).isRequired,
    location: shape({
        search: string
    }).isRequired
};
export default LoginPanel;
