export const START_USER_LOGIN = 'START_USER_LOGIN';
export const FINISH_USER_LOGIN = 'FINISH_USER_LOGIN';
export const LOGIN_USER = 'LOGIN_USER';
export const USER_AUTHENTICATION_SUCCEEDED = 'USER_AUTHENTICATION_SUCCEEDED';
export const USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const START_USER_LOGOUT = 'START_USER_LOGOUT';
export const FINISH_USER_LOGOUT = 'FINISH_USER_LOGOUT';
export const SHOW_ACTIVATE_SUCCESS_MESSAGE = 'SHOW_ACTIVATE_SUCCESS_MESSAGE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
