import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { getResourcesState } from 'store/layout/selectors';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { fetchActions, runAction } from './remoteApi';

function* fetchActionsApiCall() {
    const results = yield apiCall(fetchActions);
    yield put(actions.initActions(results));
}

function* fetchActionsGenerator() {
    yield guardedGenerator(fetchActionsApiCall);
}

export function* watchFetchActions() {
    yield guardedTakeEvery(actionTypes.FETCH_ACTIONS, fetchActionsGenerator);
}

function* runActionApiCall(action) {
    yield apiCall(runAction, action.actionToRun);
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.runActionSuccess));
}

function* runActionGenerator(action) {
    yield put(actions.startRunAction(action.actionToRun));
    yield guardedGenerator(runActionApiCall, action);
    yield put(actions.finishRunAction(action.actionToRun));
}

export function* watchRunAction() {
    yield guardedTakeEvery(actionTypes.RUN_ACTION, runActionGenerator);
}
