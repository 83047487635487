import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeUser } from 'infrastructure/helpers/metaTypes';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getResourcesState } from 'store/layout/selectors';
import { fetchElementData } from '../element/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import {
    changeUserEmailApi
} from './remoteApi';

function* changeUserEmailInApi(action) {
    const changeUserEmailRequestBody = {
        newUserEmail: action.newUserEmail,
    };
    if (isMetaTypeUser(action.currentElement.metaTypeName)) {
        yield apiCall(changeUserEmailApi, action.currentElement.id, changeUserEmailRequestBody);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.changeUserEmail_SuccessMessage));
    yield put(actions.closeChangeUserEmailModal());
    yield put(actions.cleanChangeUserEmailModal());
    yield put(fetchElementData(action.currentElement.id, action.currentElement.metaTypeName));
}

function* changeUserEmail(action) {
    yield put(actions.startChangingUserEmail());
    yield guardedGenerator(changeUserEmailInApi, action);
    yield put(actions.finishChangingUserEmail());
}

export function* watchChangeUserEmail() {
    yield guardedTakeEvery(actionTypes.CHANGE_USER_EMAIL, changeUserEmail);
}
