import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/newElement/actionTypes';

export const newElementReducer = (state = defaultStore.userManagement.newElement, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE:
            return { ...state, isFetchingAvailableTypes: true };
        case actionTypes.FINISH_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE:
            return { ...state, isFetchingAvailableTypes: false };
        case actionTypes.INIT_AVAILABLE_TYPES_FOR_PARENT_METATYPE:
            return { ...state, availableTypes: action.types };
        case actionTypes.START_CREATING_NEW_ELEMENT:
            return { ...state, isCreatingNewElement: true };
        case actionTypes.FINISH_CREATING_NEW_ELEMENT:
            return { ...state, isCreatingNewElement: false };
        case actionTypes.OPEN_NEW_ELEMENT_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_NEW_ELEMENT_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_NEW_ELEMENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_NEW_ELEMENT_MODAL:
            return {
                ...state,
                availableTypes: defaultStore.userManagement.newElement.availableTypes,
                currentElement: null
            };
        default:
            return state;
    }
};
