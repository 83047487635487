import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/changeElementId/actionTypes';

export const changeElementIdReducer = (state = defaultStore.userManagement.changeElementId, action) => {
    switch (action.type) {
        case actionTypes.START_CHANGING_ELEMENT_ID:
            return { ...state, isChangingElementId: true };
        case actionTypes.FINISH_CHANGING_ELEMENT_ID:
            return { ...state, isChangingElementId: false };
        case actionTypes.OPEN_CHANGE_ELEMENT_ID_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_CHANGE_ELEMENT_ID_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_CHANGE_ELEMENT_ID_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_CHANGE_ELEMENT_ID_MODAL:
            return {
                ...state,
                currentElement: null
            };
        default:
            return state;
    }
};
