import React from 'react';
import PropTypes from 'prop-types';

class ResourcesProvider extends React.Component {
    // eslint-disable-next-line react/static-property-placement
    static childContextTypes = {
        resources: PropTypes.shape({})
    }

    getChildContext() {
        // eslint-disable-next-line react/destructuring-assignment
        return { resources: this.props.resources };
    }

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children;
    }
}

ResourcesProvider.propTypes = {
    resources: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    children: PropTypes.node.isRequired
};

export default ResourcesProvider;
