import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { isMetaTypeArticle, isMetaTypeCategory } from 'infrastructure/helpers/metaTypes';
import { changeElementIdInTree } from 'api/catalog/productTree/actions';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { push } from 'infrastructure/messaging/actions';
import { getResourcesState } from 'store/layout/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { changeArticleIdApi, changeCategoryIdApi } from './remoteApi';

function* changeElementIdInApi(action) {
    const changeElementIdRequestBody = {
        newElementId: action.newElementId,
    };
    if (isMetaTypeCategory(action.currentElement.metaTypeName)) {
        yield apiCall(changeCategoryIdApi, action.currentElement.id, changeElementIdRequestBody);
    }
    if (isMetaTypeArticle(action.currentElement.metaTypeName)) {
        yield apiCall(changeArticleIdApi, action.currentElement.id, changeElementIdRequestBody);
    }
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.changeElementId_SuccessMessage));
    yield put(actions.closeChangeElementIdModal());
    yield put(actions.cleanChangeElementIdModal());
    yield put(changeElementIdInTree(action.currentElement.id, action.newElementId));
    yield (put(push(`/${action.elementArea}/${action.currentElement.metaTypeName}/${action.newElementId}`)));
}

function* changeElementId(action) {
    yield put(actions.startChangingElementId());
    yield guardedGenerator(changeElementIdInApi, action);
    yield put(actions.finishChangingElementId());
}

export function* watchChangeElementId() {
    yield guardedTakeEvery(actionTypes.CHANGE_ELEMENT_ID, changeElementId);
}
