import * as actionTypes from './actionTypes';

export const fetchElementData = (elementId, elementMetaType) => ({ type: actionTypes.FETCH_ELEMENT_DATA, elementId, elementMetaType });
export const startFetchingElementData = () => ({ type: actionTypes.START_FETCHING_ELEMENT_DATA });
export const finishFetchingElementData = () => ({ type: actionTypes.FINISH_FETCHING_ELEMENT_DATA });
export const initElementData = (elementData) => ({ type: actionTypes.INIT_ELEMENT_DATA, elementData });

export const fetchElementConfiguration = (elementMetaType, elementType) => ({ type: actionTypes.FETCH_ELEMENT_CONFIGURATION, elementMetaType, elementType });
export const startFetchingElementConfiguration = () => ({ type: actionTypes.START_FETCHING_ELEMENT_CONFIGURATION });
export const finishFetchingElementConfiguration = () => ({ type: actionTypes.FINISH_FETCHING_ELEMENT_CONFIGURATION });
export const initElementConfiguration = (elementConfigurationData) => ({ type: actionTypes.INIT_ELEMENT_CONFIGURATION, elementConfigurationData });

export const saveElementFormData = (elementMetaType, elementId, formName, form, elementArea) => ({ type: actionTypes.SAVE_ELEMENT_FORM_DATA, elementMetaType, elementId, formName, form, elementArea });

export const startSavingElementData = () => ({ type: actionTypes.START_SAVING_ELEMENT_DATA });
export const finishSavingElementData = () => ({ type: actionTypes.FINISH_SAVING_ELEMENT_DATA });
