import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/element/actionTypes';

export const elementReducer = (state = defaultStore.userManagement.element, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_ELEMENT_DATA:
            return { ...state, isFetchingData: true };
        case actionTypes.FINISH_FETCHING_ELEMENT_DATA:
            return { ...state, isFetchingData: false };
        case actionTypes.START_SAVING_ELEMENT_DATA:
            return { ...state, isSavingData: true };
        case actionTypes.FINISH_SAVING_ELEMENT_DATA:
            return { ...state, isSavingData: false };
        case actionTypes.INIT_ELEMENT_DATA:
            return { ...state, elementData: action.elementData };
        default:
            return state;
    }
};
export const elementTypeConfigurationReducer = (state = defaultStore.userManagement.elementTypeConfiguration, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_ELEMENT_CONFIGURATION:
            return { ...state, isFetchingConfigurationData: true };
        case actionTypes.FINISH_FETCHING_ELEMENT_CONFIGURATION:
            return { ...state, isFetchingConfigurationData: false };
        case actionTypes.INIT_ELEMENT_CONFIGURATION:
            return { ...state, elementConfigurationData: action.elementConfigurationData };
        default:
            return state;
    }
};
