import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback } from 'reactstrap';
import DropdownList from 'react-widgets/lib/DropdownList';

const Dropdown = ({ input, meta: { error, warning, touched, invalid, valid }, ...rest }) => (
    <>
        <DropdownList
            {...input}
            invalid={touched ? invalid : undefined}
            valid={!touched ? undefined : valid}
            {...rest}
        />
        {touched && <FormFeedback valid={!touched ? undefined : valid}>{error || warning}</FormFeedback>}
    </>
);

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
    meta: PropTypes.shape({
        invalid: PropTypes.bool,
        error: PropTypes.string,
        warning: PropTypes.string,
        touched: PropTypes.bool,
        valid: PropTypes.bool,
    }).isRequired,
    input: PropTypes.shape({
        component: PropTypes.shape({}).isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        textField: PropTypes.string,
        valueField: PropTypes.string,
        groupComponent: PropTypes.string,
        groupBy: PropTypes.string,
        data: PropTypes.arrayOf.isRequired,
        onBlur: PropTypes.func,
    }).isRequired,
};

export default Dropdown;
