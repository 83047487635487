import * as actionTypes from './actionTypes';

export const changeElementId = (currentElement, newElementId, elementArea) => ({ type: actionTypes.CHANGE_ELEMENT_ID, currentElement, newElementId, elementArea });
export const startChangingElementId = () => ({ type: actionTypes.START_CHANGING_ELEMENT_ID });
export const finishChangingElementId = () => ({ type: actionTypes.FINISH_CHANGING_ELEMENT_ID });

export const openChangeElementIdModal = (currentElement) => ({ type: actionTypes.OPEN_CHANGE_ELEMENT_ID_MODAL, currentElement });
export const closeChangeElementIdModal = () => ({ type: actionTypes.CLOSE_CHANGE_ELEMENT_ID_MODAL });
export const toggleChangeElementIdModal = () => ({ type: actionTypes.TOGGLE_CHANGE_ELEMENT_ID_MODAL });
export const cleanChangeElementIdModal = () => ({ type: actionTypes.CLEAN_CHANGE_ELEMENT_ID_MODAL });
