import * as actionTypes from './actionTypes';

export const fetchSearchResults = (searchPhrase, pageIndex, pageSize, searchMetaTypes, searchLanguage) => ({
    type: actionTypes.FETCH_SEARCH_RESULTS,
    searchPhrase,
    pageIndex,
    pageSize,
    searchMetaTypes,
    searchLanguage
});
export const startFetchingSearchResults = () => ({ type: actionTypes.START_FETCHING_SEARCH_RESULTS });
export const finishFetchingSearchResults = () => ({ type: actionTypes.FINISH_FETCHING_SEARCH_RESULTS });
export const initSearchResults = (searchResults) => ({ type: actionTypes.INIT_SEARCH_RESULTS, searchResults });
export const toggleSearchElementSelection = (elementName, metaType) => ({ type: actionTypes.TOGGLE_SEARCH_ELEMENT_SELECTION, elementName, metaType });
export const clearSearchResults = () => ({ type: actionTypes.CLEAR_SEARCH_RESULTS });
export const changeSearchPhrase = (searchPhrase) => ({ type: actionTypes.CHANGE_SEARCH_PHRASE, searchPhrase });
export const initSearchPhrase = (searchPhrase) => ({ type: actionTypes.INIT_SEARCH_PHRASE, searchPhrase });
export const startFetchingSearchSuggestions = () => ({ type: actionTypes.START_FETCHING_SEARCH_SUGGESTIONS });
export const initSearchSuggestions = (searchSuggestions) => ({ type: actionTypes.INIT_SEARCH_SUGGESTIONS, searchSuggestions });
export const finishFetchingSearchSuggestions = () => ({ type: actionTypes.FINISH_FETCHING_SEARCH_SUGGESTIONS });
export const fetchPredefinedSearchOptions = () => ({ type: actionTypes.FETCH_PREDEFINED_SEARCH_OPTIONS });
export const initPredefinedSearchOptions = (options) => ({ type: actionTypes.INIT_PREDEFINED_SEARCH_OPTIONS, options });
export const setPagination = (pageIndex, pageSize) => ({ type: actionTypes.SET_PAGINATION, pageIndex, pageSize });
export const fetchPredefinedSearchResults = (pageIndex, pageSize, predefinedSearchId) => ({
    type: actionTypes.FETCH_PREDEFINED_SEARCH_RESULTS,
    pageIndex,
    pageSize,
    predefinedSearchId
});
