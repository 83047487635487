import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/catalog/exportElement/actionTypes';

export const exportElementReducer = (state = defaultStore.catalog.exportElement, action) => {
    switch (action.type) {
        case actionTypes.START_ADDING_EXPORT_ELEMENT:
            return { ...state, isAddingExport: true };
        case actionTypes.FINISH_ADDING_EXPORT_ELEMENT:
            return { ...state, isAddingExport: false };
        case actionTypes.INIT_AVAILABLE_EXPORT_DEFINITIONS:
            return { ...state, availableExportDefinitions: action.exportDefinitions };
        case actionTypes.OPEN_EXPORT_ELEMENT_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_EXPORT_ELEMENT_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_EXPORT_ELEMENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_EXPORT_ELEMENT_MODAL:
            return {
                ...state,
                currentElement: null
            };
        default:
            return state;
    }
};

export const exportDefinitionReducer = (state = defaultStore.catalog.exportDefinition, action) => {
    switch (action.type) {
        case actionTypes.OPEN_EXPORT_DEFINITION_MODAL:
            return { ...state, isExportDefinitionModalOpen: true };
        case actionTypes.CLOSE_EXPORT_DEFINITION_MODAL:
            return { ...state, isExportDefinitionModalOpen: false };
        case actionTypes.TOGGLE_EXPORT_DEFINITION_MODAL:
            return {
                ...state,
                isExportDefinitionModalOpen: !state.isExportDefinitionModalOpen,
            };
        case actionTypes.CLEAN_EXPORT_DEFINITION_MODAL:
            return {
                isExportDefinitionModalOpen: false,
                ...state,
            };
        case actionTypes.INIT_EXPORT_DEFINITIONS_INITIAL_OPTIONS: 
            return {...state, exportDefinitionsInitialOptions: { ...action.initialOptions } };
        case actionTypes.INIT_EXPORT_DEFINITION_AVAILABLE_PROPERTIES: 
            return {...state, exportDefinitionsAvailableProperties: action.initialOptions };
        default:
            return state;
    }
};
