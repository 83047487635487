import { http } from 'infrastructure/http';

export const fetchAvailableExportDefinitionsApi = () => http.get('/api/data-management/export/exportdefinitions');

export const createNewExportJob = (requestBody) => http.post('api/data-management/export', requestBody);

export const fetchExportDefinitionInitialOptionsApi = () => http.get('/api/data-management/export/exportdefinition/options');

export const fetchMetaTypeAvailablePropertiesApi = (metaType) => http.get(`/api/data-management/export/exportdefinition/metaproperties?metaType=${metaType}`);

export const addNewExportDefinitionApi = (requestBody) => http.post('api/data-management/export/exportdefinition/create', requestBody)