import React from 'react';
import { FormFeedback, CustomInput } from 'reactstrap';

const FormCustomInput = ({ input, meta: { error, warning, touched, invalid }, type, ...rest }) => (
    <div className="custom-input-wrapper">
        <CustomInput {...input} type={type} invalid={touched ? invalid : undefined} {...rest} />
        <FormFeedback valid={!touched ? undefined : undefined}>{error || warning}</FormFeedback>
    </div>
);

export default FormCustomInput;
