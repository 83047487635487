import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/changeUserEmail/actionTypes';

export const changeUserEmailReducer = (state = defaultStore.userManagement.changeUserEmail, action) => {
    switch (action.type) {
        case actionTypes.START_CHANGING_USER_EMAIL:
            return { ...state, isChangingUserEmail: true };
        case actionTypes.FINISH_CHANGING_USER_EMAIL:
            return { ...state, isChangingUserEmail: false };
        case actionTypes.OPEN_CHANGE_USER_EMAIL_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_CHANGE_USER_EMAIL_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_CHANGE_USER_EMAIL_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_CHANGE_USER_EMAIL_MODAL:
            return {
                ...state,
                currentElement: null
            };
        default:
            return state;
    }
};
