import React, { useState } from 'react';
import { func, string, bool, shape } from 'prop-types';
import { Col, Row, Button, FormGroup, Label, Alert } from 'reactstrap';
import { Field } from 'redux-form';
import { FormInput, validatorTypes, validatorFactory } from 'components/Forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoginForm = ({ handleSubmit, authenticationFailed, resources, isAuthenticating }) => {
    const [isPasswordHidden, setIsPasswordHidden] = useState(true);
    const togglePassword = () => setIsPasswordHidden(!isPasswordHidden);
    const requiredEmail = validatorFactory(resources.emailRequiredText, validatorTypes.VALIDATOR_TYPE_REQUIRED);
    const requiredPassword = validatorFactory(resources.passwordRequiredText, validatorTypes.VALIDATOR_TYPE_REQUIRED);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                {authenticationFailed
                    && <Alert color="danger">{resources.wrongAuthenticationText}</Alert>}
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="email">{resources.emailText}</Label>
                            <Field
                                component={FormInput}
                                type="text"
                                name="email"
                                id="email"
                                placeholder={resources.emailPlaceholderText}
                                required
                                validate={[requiredEmail]} />
                        </FormGroup>
                    </Col>
                    <Col md={6} className="d-flex">
                        <FormGroup className="flex-grow-2">
                            <Label for="email">{resources.passwordText}</Label>
                            <Field
                                component={FormInput}
                                name="password"
                                id="password"
                                placeholder="Password here..."
                                label="Password"
                                required
                                type={`${isPasswordHidden ? 'password' : 'text'}`}
                                validate={[requiredPassword]} />
                        </FormGroup>

                        <button type="button" className="btn-icon btn-icon-only border-0 bg-white text-primary" onClick={togglePassword}>
                            <FontAwesomeIcon icon={isPasswordHidden ? faEye : faEyeSlash} className="btn-icon-wrapper" />
                        </button>

                    </Col>
                </Row>
                <Row className="divider" />
                <div className="d-flex align-items-center">
                    <div className="ml-auto">
                        <FormGroup>
                            <Button className="mb-2 mr-2" color="primary" type="submit" disabled={isAuthenticating}>{resources.loginText}</Button>
                        </FormGroup>
                    </div>
                </div>
            </form>
        </div>
    );
};
LoginForm.propTypes = {
    handleSubmit: func.isRequired,
    authenticationFailed: bool.isRequired,
    isAuthenticating: bool.isRequired,
    resources: shape({
        emailText: string,
        passwordPlaceholderText: string,
        passwordText: string,
        emailPlaceholderText: string,
        wrongAuthenticationText: string,
        restorePasswordText: string,
        loginText: string,
        emailRequiredText: string,
        passwordRequiredText: string,
    }).isRequired
};
export default LoginForm;
