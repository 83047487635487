import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { getFieldElementData } from 'store/propertyValueSuggestions/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getFieldSuggestions } from './remoteApi';

function* getFieldSuggestionsApi(action) {
    const suggestions = yield apiCall(getFieldSuggestions, action.metatypeName, action.propertyName, action.query);
    yield put(actions.initFieldSuggestions(action.metatypeName, action.propertyName, action.query, suggestions));
}

function* changeFieldSuggestions(action) {
    const element = yield (select(getFieldElementData));
    if (element.metatypeName === action.metatypeName && element.propertyData.propertyName === action.propertyName && element.propertyData.suggestions.searchPhrase === action.query && !element.propertyData.isFetchingSuggestions) {
        return;
    }
    yield put(actions.startFetchingFieldSuggestions());
    yield guardedGenerator(getFieldSuggestionsApi, action);
    yield put(actions.finishFetchingFieldSuggestions());
}

export function* watchChangeFieldSuggestions() {
    yield guardedTakeEvery(actionTypes.FETCH_FIELD_SUGGESTIONS, changeFieldSuggestions);
}
