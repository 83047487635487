import { call, put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import * as actions from 'api/dataManagement/actions';
import * as actionTypes from 'api/dataManagement/actionTypes';
import {
    getExportsApi,
    downloadExportFileFromApi,
    deleteExportFileFromApi
} from 'api/dataManagement/remoteApi';
import { showFile } from 'views/fileOpener';

function* fetchAllExportsFromApi() {
    const data = yield apiCall(getExportsApi);
    yield put(actions.initAllExports(data));
}

function* fetchAllExports(action) {
    yield put(actions.startFetchingAllExports());
    yield guardedGenerator(fetchAllExportsFromApi, action);
    yield put(actions.finishFetchingAllExports());
}

export function* watchFetchAllExports() {
    yield guardedTakeEvery(actionTypes.FETCH_ALL_EXPORTS, fetchAllExports);
}

function* downloadExportFile(action) {
    yield put(actions.startExportAction());
    const data = yield apiCall(downloadExportFileFromApi, action.exportJobId);
    yield call(showFile, data.fileContents, data.fileDownloadName, data.contentType);
    yield put(actions.finishExportAction());
}

export function* watchDownloadExportFile() {
    yield guardedTakeEvery(actionTypes.DOWNLOAD_EXPORT_FILE, downloadExportFile);
}

function* removeExport(action) {
    yield put(actions.startExportAction());
    yield apiCall(deleteExportFileFromApi, action.exportJobId);
    yield fetchAllExports()
    yield put(actions.finishExportAction());
}

export function* watchRemoveExport() {
    yield guardedTakeEvery(actionTypes.REMOVE_EXPORT, removeExport);
}
