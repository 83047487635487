import * as actionTypes from './actionTypes';

export const fetchAvailableTypesForParentMetaType = (metaTypeName) => ({ type: actionTypes.FETCH_AVAILABLE_TYPES_FOR_PARENT_METATYPE, metaTypeName });
export const startFetchingAvailableTypesForParentMetaType = () => ({ type: actionTypes.START_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE });
export const initAvailableTypesForParentMetaType = (types) => ({ type: actionTypes.INIT_AVAILABLE_TYPES_FOR_PARENT_METATYPE, types });
export const finishFetchingAvailableTypesForParentMetaType = () => ({ type: actionTypes.FINISH_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE });

export const createNewElement = (newElementData, currentElement, elementArea) => ({ type: actionTypes.CREATE_NEW_ELEMENT, newElementData, currentElement, elementArea });
export const startCreatingNewElement = () => ({ type: actionTypes.START_CREATING_NEW_ELEMENT });
export const finishCreatingNewElement = () => ({ type: actionTypes.FINISH_CREATING_NEW_ELEMENT });

export const openNewElementModal = (currentElement) => ({ type: actionTypes.OPEN_NEW_ELEMENT_MODAL, currentElement });
export const closeNewElementModal = () => ({ type: actionTypes.CLOSE_NEW_ELEMENT_MODAL });
export const toggleNewElementModal = () => ({ type: actionTypes.TOGGLE_NEW_ELEMENT_MODAL });
export const cleanNewElementModal = () => ({ type: actionTypes.CLEAN_NEW_ELEMENT_MODAL });
