import _ from 'lodash';
import * as actionTypes from 'api/cms/cmsTree/actionTypes';
import defaultStore from 'store/defaultStore';

const nodeNameIdString = '__&__';

const getFetchedElementWithPath = (elements, path, preexistingElements) => {
    const result = elements.map((e) => {
        const isExpanded = (preexistingElements && preexistingElements.some((pE) => `${path}${nodeNameIdString}${e.id}${nodeNameIdString}` === pE.id && pE.expanded));
        return ({
            ...e,
            title: e.isDirectory && e.childrenCount
                ? `${e.title} (${e.childrenCount})`
                : e.title,
            nodeName: e.id,
            elementId: e.id,
            id: `${path}${nodeNameIdString}${e.id}${nodeNameIdString}`,
            parentName: e.parent,
            parent: path,
            expanded: isExpanded
        });
    });
    return result;
};

const getStubElements = (elements, path, preexisitingElements) => {
    const result = elements.filter((e) => e.isDirectory
        && (!preexisitingElements || !preexisitingElements.some((pE) => `${path}${nodeNameIdString}${e.id}${nodeNameIdString}` === pE.id && pE.expanded))).map((d) => {
        const parentKey = `${path}${nodeNameIdString}${d.id}${nodeNameIdString}`;
        return ({
            id: `stubId${parentKey}`,
            title: 'loading',
            subtitle: false,
            isDirectory: false,
            parent: `${parentKey}`,
            parentName: d.id
        });
    });
    return result;
};

const getTreeWithFetchingNode = (tree, currentNode, isFetching) => {
    const newTree = _.cloneDeep(tree);
    if (currentNode && currentNode.nodePath) {
        const currentNodeId = tree.findIndex((e) => e.id === currentNode.nodePath);
        newTree[currentNodeId] = { ...newTree[currentNodeId], isFetching };
    }
    return newTree;
};

const getTreeWithExpandedNode = (tree, currentNode) => {
    const newTree = _.cloneDeep(tree);
    const currentNodeId = tree.findIndex((e) => e.id === currentNode.nodePath);
    newTree[currentNodeId] = { ...newTree[currentNodeId], expanded: currentNode.expanded };
    return newTree;
};

export const cmsTreeReducer = (state = defaultStore.cms.cmsTree, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_CMS_TREE_ELEMENTS: {
            if (state.isFetchingWholeTree) {
                return { ...state, isFetching: true, isFetchingWholeTree: true, tree: [...getTreeWithFetchingNode(state.tree, action.currentNode, true)] };
            }
            return { ...state, isFetching: true, tree: [...getTreeWithFetchingNode(state.tree, action.currentNode, true)] };
        }
        case actionTypes.INIT_CMS_TREE_ELEMENTS: {
            const stubElements = getStubElements(action.elements, action.rootKey);
            const fetchedElements = getFetchedElementWithPath(action.elements, action.rootKey);

            if (state.tree && state.tree.length > 0) {
                return state;
            }
            return { ...state, tree: [...fetchedElements, ...stubElements] };
        }
        case actionTypes.COLLAPSE_CMS_TREE_ELEMENT: {
            return { ...state, tree: [...getTreeWithExpandedNode(state.tree, action.currentNode)] };
        }
        case actionTypes.EXPAND_CMS_TREE_ELEMENT: {
            const path = action.currentNode.nodePath;
            const expandStubElements = getStubElements(action.elements, path);
            const expandFetchedElements = getFetchedElementWithPath(action.elements, path);

            const newTree = getTreeWithExpandedNode(state.tree, action.currentNode);
            if (action.needsAddingNewElements) {
                const newTreeWithoutLoadingChildren = [...newTree.filter((e) => e.parent !== path)];
                return { ...state, tree: [...newTreeWithoutLoadingChildren, ...expandFetchedElements, ...expandStubElements] };
            }
            return { ...state, tree: [...newTree] };
        }
        case actionTypes.FINISH_FETCHING_CMS_TREE_ELEMENTS: {
            return { ...state, isFetching: false, tree: [...getTreeWithFetchingNode(state.tree, action.currentNode, false, false)], isFetchingWholeTree: false };
        }
        case actionTypes.START_REFRESHING_CMS_TREE_ELEMENTS: {
            return { ...state, isRefreshing: true };
        }
        case actionTypes.INIT_REFRESHING_CMS_TREE_ELEMENTS: {
            let refreshingNodes = state.tree.filter((x) => x.elementId === action.data.nodeName);
            if (refreshingNodes === null || refreshingNodes.length === 0) {
                refreshingNodes = [{ id: action.data.nodeName }];
            }

            const refreshingNodeIds = refreshingNodes.map((x) => x.id);
            let refreshResults = refreshingNodes.map((refreshingNode) => {
                const refreshedStubElements = getStubElements(action.elements, refreshingNode.id, state.tree);
                const refreshedFetchedElements = getFetchedElementWithPath(action.elements, refreshingNode.id, state.tree);
                return [...refreshedStubElements, ...refreshedFetchedElements];
            });

            refreshResults = refreshResults.flat();
            const refreshedElementIds = refreshResults.map((x) => x.id);

            const newTreeWithoutNodeChildren = [...state.tree.filter((e) => !refreshingNodeIds.includes(e.parent))];
            const newTreeWithoutIdDuplicates = [...newTreeWithoutNodeChildren.filter((e) => !refreshedElementIds.includes(e.id))];

            const expandedNodes = newTreeWithoutIdDuplicates.filter((e) => e.expanded);
            const expandedNodeNames = expandedNodes.map((e) => e.nodeName);
            const newTreeWithoutObsoleteStubs = [...newTreeWithoutIdDuplicates.filter((e) => !(expandedNodeNames.includes(e.parentName) && e.id.startsWith('stubId')))];

            return { ...state, tree: [...newTreeWithoutObsoleteStubs, ...refreshResults] };
        }
        case actionTypes.CHANGE_ELEMENT_CMS_ID_IN_TREE: {
            const { currentElementId, newElementId } = action;
            return {
                ...state,
                tree: state.tree.map((x) => ({
                    ...x,
                    id: x.id.replace(`${nodeNameIdString}${currentElementId}${nodeNameIdString}`, `${nodeNameIdString}${newElementId}${nodeNameIdString}`),
                    parent: x.parent.replace(`${nodeNameIdString}${currentElementId}${nodeNameIdString}`, `${nodeNameIdString}${newElementId}${nodeNameIdString}`),
                    elementId: x.elementId === currentElementId ? newElementId : x.elementId,
                    nodeName: x.nodeName === currentElementId ? newElementId : x.nodeName,
                    parentName: x.parentName === currentElementId ? newElementId : x.parentName
                }))
            };
        }
        case actionTypes.FINISH_REFRESHING_CMS_TREE_ELEMENTS: {
            return { ...state, isRefreshing: false };
        }
        case actionTypes.START_FETCHING_CMS_TREE_CONFIG:
            return { ...state, isFetchingConfig: true };
        case actionTypes.FINISH_FETCHING_CMS_TREE_CONFIG:
            return { ...state, isFetchingConfig: false };
        case actionTypes.INIT_CMS_TREE_CONFIG:
            return { ...state, config: action.config };
        default:
            return state;
    }
};
