import * as actionTypes from 'api/layout/actionTypes';
import { RAISE_MESSAGE, CLEAR_MESSAGE } from 'infrastructure/errorHandling/actionTypes';
import defaultStore from '../defaultStore';

export const layoutReducer = (state = defaultStore.layout, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_LAYOUT_INFO: {
            return { ...state, isFetching: true };
        }
        case actionTypes.INIT_LAYOUT_INFO: {
            return { ...state, layoutInfo: action.layoutInfo };
        }
        case actionTypes.FINISH_FETCHING_LAYOUT_INFO: {
            return { ...state, isFetching: false };
        }
        case actionTypes.SET_SELECTED_LANGUAGES: {
            const { name, isActive } = action.selectedLanguage;
            return {
                ...state,
                layoutInfo: {
                    ...state.layoutInfo,
                    supportedTranslations: state.layoutInfo.supportedTranslations.map((x) => (x.name === name ? { ...x, isActive } : { ...x }))
                }
            };
        }
        default: {
            return state;
        }
    }
};

export const messagePlaceholderReducer = (state = defaultStore.messagePlaceholder, action) => {
    switch (action.type) {
        case RAISE_MESSAGE:
            return { messages: [...state.messages, { messageType: action.messageType, message: action.message, show: true, messageCode: action.messageCode }], };
        case CLEAR_MESSAGE: {
            const index = state.messages.indexOf(action.message);
            const newMessages = [...state.messages];
            newMessages.splice(index, 1);

            return { show: false, messages: newMessages };
        }
        default:
            return state;
    }
};
