export const FETCH_CMS_TREE_ELEMENTS = 'FETCH_CMS_TREE_ELEMENTS';
export const START_FETCHING_CMS_TREE_ELEMENTS = 'START_FETCHING_CMS_TREE_ELEMENTS';
export const FINISH_FETCHING_CMS_TREE_ELEMENTS = 'FINISH_FETCHING_CMS_TREE_ELEMENTS';
export const INIT_CMS_TREE_ELEMENTS = 'INIT_CMS_TREE_ELEMENTS';
export const EXPAND_CMS_TREE_ELEMENT = 'EXPAND_CMS_TREE_ELEMENT';
export const COLLAPSE_CMS_TREE_ELEMENT = 'COLLAPSE_CMS_TREE_ELEMENT';
export const REFRESH_CMS_TREE_ELEMENT = 'REFRESH_CMS_TREE_ELEMENT';
export const START_REFRESHING_CMS_TREE_ELEMENTS = 'START_REFRESHING_CMS_TREE_ELEMENTS';
export const FINISH_REFRESHING_CMS_TREE_ELEMENTS = 'FINISH_REFRESHING_CMS_TREE_ELEMENTS';
export const INIT_REFRESHING_CMS_TREE_ELEMENTS = 'INIT_REFRESHING_CMS_TREE_ELEMENTS';
export const CHANGE_ELEMENT_CMS_ID_IN_TREE = 'CHANGE_ELEMENT_CMS_ID_IN_TREE';

export const FETCH_CMS_TREE_CONFIG = 'FETCH_CMS_TREE_CONFIG';
export const START_FETCHING_CMS_TREE_CONFIG = 'START_FETCHING_CMS_TREE_CONFIG';
export const FINISH_FETCHING_CMS_TREE_CONFIG = 'FINISH_FETCHING_CMS_TREE_CONFIG';
export const INIT_CMS_TREE_CONFIG = 'INIT_CMS_TREE_CONFIG';
