import React from 'react';
import { Input, FormFeedback } from 'reactstrap';

const FormInput = ({
    input,
    label,
    meta: { error, warning, touched, invalid, valid },
    type,
    ...rest }) => {
    return (
        <>
            <Input
                {...input}
                type={type}
                invalid={touched ? invalid : undefined}
                valid={!touched ? undefined : valid}
                {...rest} />
            {touched && <FormFeedback valid={!touched ? undefined : valid}>{error || warning}</FormFeedback>}
        </>
    );
};

export default FormInput;
