import { put, select } from 'redux-saga/effects';
import diff from 'object-diff';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getFormInitialValues } from 'redux-form';
import { push } from 'infrastructure/messaging/actions';
import { getResourcesState } from 'store/layout/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import {
    getMediaApi,
    getFileNamesInfoApi,
    getMediaDataApi,
    saveMediaDataApi,
    getMediaConfigurationApi,
    deleteMediaElementsApi,
    getMediaInfosApi,
    getAttachmentAssociationsApi,
    getSupportedExtensionsApi
} from './remoteApi';

const elementArea = 'media';

function* getMediaElements(action) {
    const elements = yield apiCall(getMediaApi, action.data);
    yield put(actions.initMediaElements(elements, action.data.isPrependingMedia));
}

function* fetchMediaElements(action) {
    yield put(actions.startFetchingMediaElements());
    yield guardedGenerator(getMediaElements, action);
    yield put(actions.finishFetchingMediaElements());
}

export function* watchFetchMediaElements() {
    yield guardedTakeEvery(actionTypes.FETCH_MEDIA_ELEMENTS, fetchMediaElements);
}

function* getFileNamesInfo(action) {
    const fileNamesInfo = yield apiCall(getFileNamesInfoApi, action.fileNames);
    yield put(actions.initFileNamesInfo(fileNamesInfo));
}

function* fetchFileNamesInfo(action) {
    yield put(actions.startFetchingFileNamesInfo());
    yield guardedGenerator(getFileNamesInfo, action);
    yield put(actions.finishFetchingFileNamesInfo());
}

export function* watchFileNamesInfo() {
    yield guardedTakeEvery(actionTypes.FETCH_FILE_NAMES_INFO, fetchFileNamesInfo);
}

function* getMediaData(action) {
    const mediaData = yield apiCall(getMediaDataApi, action.elementId);
    yield put(actions.initMediaData(mediaData));
}

function* fetchMediaData(action) {
    yield put(actions.startFetchingMediaData());
    yield getMediaData(action);
    yield put(actions.finishFetchingMediaData());
}

export function* watchFetchMediaData() {
    yield guardedTakeEvery(actionTypes.FETCH_MEDIA_DATA, fetchMediaData);
}

function* getMediaConfiguration(action) {
    const mediaConfigurationData = yield apiCall(getMediaConfigurationApi, action.elementType);
    yield put(actions.initMediaConfiguration(mediaConfigurationData));
}

function* fetchMediaConfiguration(action) {
    yield put(actions.startFetchingMediaConfiguration());
    yield getMediaConfiguration(action);
    yield put(actions.finishFetchingMediaConfiguration());
}

export function* watchFetchMediaConfiguration() {
    yield guardedTakeEvery(actionTypes.FETCH_MEDIA_CONFIGURATION, fetchMediaConfiguration);
}

function* saveMediaFormData(action) {
    yield put(actions.startSavingMediaData());
    const initialValues = yield select(getFormInitialValues(action.formName));
    const delta = diff(initialValues, action.form);
    const associationsDelta = diff(initialValues.associations, action.form.associations);
    const requestBody = {
        propertiesData: { ...delta, associations: undefined },
        associations: { ...associationsDelta }
    };
    yield apiCall(saveMediaDataApi, action.elementId, requestBody);
    const mediaData = yield apiCall(getMediaDataApi, action.elementId);
    yield put(actions.initMediaData(mediaData));
    yield put(actions.finishSavingMediaData());
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, 'Element was successfully saved'));
}

export function* watchSaveMediaFormData() {
    yield guardedTakeEvery(actionTypes.SAVE_MEDIA_FORM_DATA, saveMediaFormData);
}

function* deleteMediaElementsInApi(action) {
    yield apiCall(deleteMediaElementsApi, action.fileNames);
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.deleteMediaElementMessage));
    yield (put(push(`/${elementArea}`)));
    const elements = yield apiCall(getMediaApi, action.requestData);
    yield put(actions.initMediaElements(elements));
}

function* deleteMediaElements(action) {
    yield put(actions.startDeleteMediaElements());
    yield guardedGenerator(deleteMediaElementsInApi, action);
    yield put(actions.finishDeleteMediaElements());
}

export function* watchDeleteMediaElement() {
    yield guardedTakeEvery(actionTypes.DELETE_MEDIA_ELEMENTS, deleteMediaElements);
}

function* getMediaInfos(action) {
    const mediaInfos = yield apiCall(getMediaInfosApi, action.files);
    yield put(actions.initMediaInfos(mediaInfos));
}

function* fetchMediaInfos(action) {
    yield put(actions.startFetchingMediaInfos());
    yield guardedGenerator(getMediaInfos, action);
    yield put(actions.finishFetchingMediaInfos());
}

export function* watchMediaInfos() {
    yield guardedTakeEvery(actionTypes.FETCH_MEDIA_INFOS, fetchMediaInfos);
}

function* getAttachmentAssociations(action) {
    yield put(actions.initAttachmentAssociations([]));
    const data = yield apiCall(getAttachmentAssociationsApi, action.elementId);
    yield put(actions.initAttachmentAssociations(data));
}

function* fetchAttachmentAssociations(action) {
    yield put(actions.startFetchingAttachmentAssociations());
    yield guardedGenerator(getAttachmentAssociations, action);
    yield put(actions.finishFetchingAttachmentAssociations());
}

export function* watchFetchAttachmentAssociations() {
    yield guardedTakeEvery(actionTypes.FETCH_ATTACHMENT_ASSOCIATIONS, fetchAttachmentAssociations);
}

function* getSupportedExtensions() {
    const supportedExtensions = yield apiCall(getSupportedExtensionsApi);
    yield put(actions.initSupportedExtensions(supportedExtensions));
}

function* fetchSupportedExtensions() {
    yield put(actions.startFetchingSupportedExtensions());
    yield guardedGenerator(getSupportedExtensions);
    yield put(actions.finishFetchingSupportedExtensions());
}

export function* watchSupportedExtensions() {
    yield guardedTakeEvery(actionTypes.FETCH_SUPPORTED_EXTENSIONS, fetchSupportedExtensions);
}
