const requiredTypeFactory = (message) => (value) => (value ? undefined : message);

const requiredAtLeastOneCheckbox = (message) => (value) => (value && Object.values(value).some((val) => val === true) ? undefined : message);

const properitesMatch = (message, additionalProperties) => (value, allValues) => (value !== allValues[additionalProperties.matchingParameter] ? message : undefined);

const minLentgh = (message, additionalProperties) => (value) => {
    const { minValue } = additionalProperties;
    const minLengthMessage = message?.replace(/{MIN}/i, minValue);
    return (value && value.length < minValue ? minLengthMessage : undefined);
};

const regexValidation = (message, additionalProperties) => (value) => {
    const { regex } = additionalProperties;
    return regex.test(value)
        ? undefined
        : message;
};

const idAllowedCharactersFactory = (message) => (value) => {
    const regex = /^([a-zA-Z0-9_.@-]+)$/;
    return regex.test(value)
        ? undefined
        : message;
};

const maxLength200 = (message) => (value) => {
    const maxLengthMessage = message?.replace(/{MAX}/i, 200);
    return (value && value.length > 200 ? maxLengthMessage : undefined);
};

const VALIDATOR_TYPE_REQUIRED = 'required';
const ID_ALLOWED_CHARACTERS = 'id-allowed-characters';
const MAX_LENGTH_200 = 'max-length-200';
const PROPERTIES_MATCH = 'properitesMatch';
const REGEX_VALIDATION = 'regexValidation';
const MIN_LENGTH_VALIDATION = 'minLengthValidation';
const AT_LAST_ONE_CHECKBOX_REQUIRED = 'atLeastOneCheckboxRequired';

const validatorTypeFactories = {
    [VALIDATOR_TYPE_REQUIRED]: requiredTypeFactory,
    [ID_ALLOWED_CHARACTERS]: idAllowedCharactersFactory,
    [MAX_LENGTH_200]: maxLength200,
    [PROPERTIES_MATCH]: properitesMatch,
    [REGEX_VALIDATION]: regexValidation,
    [MIN_LENGTH_VALIDATION]: minLentgh,
    [AT_LAST_ONE_CHECKBOX_REQUIRED]: requiredAtLeastOneCheckbox
};

const validators = {};
export const validatorFactory = (message, validatorType, additionalProperties) => {
    const validatorTypeFactory = validatorTypeFactories[validatorType];
    if (validatorTypeFactory) {
        let key = `${validatorType}_${message}`;
        if (additionalProperties) {
            key = `${key}_${Object.keys(additionalProperties).join('_')}_${Object.values(additionalProperties).join('_')}`;
        }
        if (!validators[key]) {
            if (additionalProperties) {
                validators[key] = validatorTypeFactory(message, additionalProperties);
            } else {
                validators[key] = validatorTypeFactory(message);
            }
        }

        return validators[key];
    }

    return null;
};

export const validatorTypes = {
    VALIDATOR_TYPE_REQUIRED,
    ID_ALLOWED_CHARACTERS,
    MAX_LENGTH_200,
    PROPERTIES_MATCH,
    REGEX_VALIDATION,
    MIN_LENGTH_VALIDATION,
    AT_LAST_ONE_CHECKBOX_REQUIRED
};
