import { call, put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import * as actions from 'api/languageManagement/actions';
import * as actionTypes from 'api/languageManagement/actionTypes';
import {
    getOnlineTranslationApi,
    getSearchTermApi,
    getExportDataApi,
    updateResourceApi
} from 'api/languageManagement/remoteApi';
import { showFile } from 'views/fileOpener';
import { getSearchTerm } from 'store/languageManagement/selectors';

function* fetchAllElementsFromApi() {
    const data = yield apiCall(getOnlineTranslationApi);
    yield put(actions.initAllElements(data));
}

function* fetchAllElements(action) {
    yield put(actions.startFetchingAllElements());
    yield guardedGenerator(fetchAllElementsFromApi, action);
    yield put(actions.finishFetchingAllElements());
}

export function* watchFetchAllElements() {
    yield guardedTakeEvery(actionTypes.FETCH_ALL_ELEMENTS, fetchAllElements);
}

function* fetchSearchTermElementsFromApi(action) {
    let data = {};
    if (action.searchTerm) {
        data = yield apiCall(getSearchTermApi, action.searchTerm);
    } else {
        data = yield apiCall(getOnlineTranslationApi);
    }
    yield put(actions.initSearchTermData(data));
}

function* fetchSearchTermData(action) {
    yield put(actions.startFetchingSearchTermData());
    yield fetchSearchTermElementsFromApi(action);
    yield put(actions.finishFetchingSearchTermData());
}

export function* watchFetchSearchTermData() {
    yield guardedTakeEvery(actionTypes.INIT_SEARCH_TERM, fetchSearchTermData);
}

function* fetchExportFromApi(action) {
    const data = yield apiCall(getExportDataApi, action.exportValues);

    yield call(showFile, data.bytes, data.fileName, data.fileType);
}

function* fetchExport(action) {
    yield put(actions.startFetchingExport());
    yield fetchExportFromApi(action);
    yield put(actions.finishFetchingExport());
}

export function* watchFetchExport() {
    yield guardedTakeEvery(actionTypes.FETCH_EXPORT, fetchExport);
}

function* updateResource(action) {
    yield apiCall(updateResourceApi, action.name, action.lang, action.value);
}

function* updateResourceAction(action) {
    yield put(actions.startFetchingAllElements());
    yield guardedGenerator(updateResource, action);
    const searchTerm = yield select(getSearchTerm);
    if (searchTerm) {
        yield guardedGenerator(fetchSearchTermElementsFromApi, { ...action, searchTerm });
    } else {
        yield guardedGenerator(fetchAllElementsFromApi, action);
    }
    yield put(actions.finishFetchingAllElements());
}

export function* watchUpdateResource() {
    yield guardedTakeEvery(actionTypes.UPDATE_RESOURCE, updateResourceAction);
}
