// eslint-disable-next-line import/no-extraneous-dependencies
import { enUS as en, de, fr, it } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import http from 'infrastructure/http/http';

const supportedLanguages = { en, de, fr, it };
export const setClientData = (initialClientData) => {
    window.clientData = window.clientData || initialClientData || {};

    // language
    const url = window.location.pathname;
    const urlSplit = url.split('/');
    if (urlSplit.length > 1) {
        const currentLanguage = urlSplit[1];
        window.clientData.currentLanguage = currentLanguage;
        http.headers['Accept-Language'] = currentLanguage;
        const supportedLanguage = supportedLanguages[currentLanguage.substr(0, 2)];
        if (supportedLanguage) {
            registerLocale(supportedLanguage.code, supportedLanguage);
            setDefaultLocale(supportedLanguage.code);
        }
    }
};
