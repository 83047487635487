import React from 'react';
import { string, shape } from 'prop-types';

import { Col, Row } from 'reactstrap';

import LoginPanel from './LoginPanel';

const LoginPage = ({ location, resources }) => (
    <>
        <div className="h-100">
            <Row className="h-100 no-gutters">
                <Col lg="12" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                    <LoginPanel location={location} resources={resources} />
                </Col>
            </Row>
        </div>
    </>
);

LoginPage.propTypes = {
    location: shape({
        location: string
    }).isRequired,
    resources: shape({
        didYouKnowText: string,
        syllableText: string,
        caloriesText: string,
        clocksText: string
    }).isRequired
};

export default LoginPage;
