import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/passwordUpdate/actionTypes';

export const passwordUpdateReducer = (state = defaultStore.userManagement.passwordUpdate, action) => {
    switch (action.type) {
        case actionTypes.OPEN_PASSWORD_UPDATE_MODAL:
            return { ...state, isModalOpen: true, elementId: action.elementId };
        case actionTypes.CLOSE_PASSWORD_UPDATE_MODAL:
            return { ...state, isModalOpen: false };
        case actionTypes.START_UPDATING_PASSWORD:
            return { ...state, isUpdatingPassword: true };
        case actionTypes.FINISH_UPDATING_PASSWORD:
            return { ...state, isUpdatingPassword: false };
        case actionTypes.TOGGLE_PASSWORD_UPDATE_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                elementId: state.isModalOpen ? null : state.elementId
            };
        default:
            return state;
    }
};
