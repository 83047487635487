import * as actionTypes from './actionTypes';

export const updateActivationOptions = (currentElement, activationOptions, elementArea, openingSource) => ({ type: actionTypes.UPDATE_ACTIVATION_OPTIONS, currentElement, activationOptions, elementArea, openingSource });

export const startUpdatingActivationOptions = () => ({ type: actionTypes.START_UPDATING_ACTIVATION_OPTIONS });
export const finishUpdatingActivationOptions = () => ({ type: actionTypes.FINISH_UPDATING_ACTIVATION_OPTIONS });

export const openActivationOptionsModal = (currentElement, openingSource) => ({ type: actionTypes.OPEN_UPDATING_ACTIVATION_OPTIONS_MODAL, currentElement, openingSource });
export const closeActivationOptionsModal = () => ({ type: actionTypes.CLOSE_UPDATING_ACTIVATION_OPTIONS_MODAL });
export const toggleActivationOptionsModal = () => ({ type: actionTypes.TOGGLE_UPDATING_ACTIVATION_OPTIONS_MODAL });
export const cleanActivationOptionsModal = () => ({ type: actionTypes.CLEAN_UPDATING_ACTIVATION_OPTIONS_MODAL });
