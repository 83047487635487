import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { getCmsTreeState } from 'store/cms/cmsTree/selectors';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getTreeElementsApi, fetchTreeConfigApi } from './remoteApi';

function* getTreeElements(action) {
    const tree = yield select(getCmsTreeState);
    let elements = [];

    const path = action.data && action.data.nodePath ? action.data.nodePath : null;

    if (action.data && action.data.nodePath && tree.findIndex((e) => e.id === action.data.nodePath) >= 0) {
        if (action.data.expanded) {
            if (tree.find((e) => e.parent === path && e.id === `stubId${action.data.nodePath}`)) {
                elements = yield apiCall(getTreeElementsApi, action.data);
                yield put(actions.expandCmsTreeElement(elements, action.data, true));
            } else {
                yield put(actions.expandCmsTreeElement(elements, action.data, false));
            }
        } else {
            yield put(actions.collapseCmsTreeElement(action.data));
        }
    }

    if (path === null && tree && (tree.length === 0 || (action.data && action.data.refresh))) {
        elements = yield apiCall(getTreeElementsApi, action.data);
        yield put(actions.initCmsTreeElements(elements, action.data, action.rootKey));
    }
}

function* fetchTreeElements(action) {
    yield put(actions.startFetchingCmsTreeElements(action.data));
    yield getTreeElements(action);
    yield put(actions.finishFetchingCmsTreeElements(action.data));
}

export function* watchFetchCmsTreeElements() {
    yield guardedTakeEvery(actionTypes.FETCH_CMS_TREE_ELEMENTS, fetchTreeElements);
}

function* getRefreshedTree(action) {
    const elements = yield apiCall(getTreeElementsApi, action);
    yield put(actions.initRefreshingCmsTreeElements(elements, action));
}

function* refreshTreeElements(action) {
    yield put(actions.startRefreshingCmsTreeElements());
    yield getRefreshedTree(action);
    yield put(actions.finishFetchingCmsTreeElements());
}

export function* watchRefreshCmsTreeElements() {
    yield guardedTakeEvery(actionTypes.REFRESH_CMS_TREE_ELEMENT, refreshTreeElements);
}

function* fetchTreeConfigFromApi() {
    const config = yield apiCall(fetchTreeConfigApi);
    yield put(actions.initCmsTreeConfig(config));
}

function* fetchTreeConfig(action) {
    yield put(actions.startFetchingCmsTreeConfig());
    yield guardedGenerator(fetchTreeConfigFromApi, action);
    yield put(actions.finishFetchingCmsTreeConfig());
}

export function* watchFetchCmsTreeConfig() {
    yield guardedTakeEvery(actionTypes.FETCH_CMS_TREE_CONFIG, fetchTreeConfig);
}
