import LoginForm from './containers/LoginForm';
import LoginFormInner from './components/LoginForm';
import LoginPage from './containers/LoginPage';
import LoginPageInner from './components/LoginPage';
import LoginPanel from './components/LoginPanel';

export const loginPageComponents = {
    LoginForm,
    LoginFormInner,
    LoginPage,
    LoginPageInner,
    LoginPanel
};
