import { put, call, takeEvery, select } from 'redux-saga/effects';
import { getResourcesState } from 'store/layout/selectors';
import { raiseMessage, raiseError, push, clearUserData, setUserData } from '../messaging/actions';
import * as messageTypes from '../messaging/messageTypes';
import { getLoginPath } from './tools';
import { authenticationContext } from '../authentication/authenticationContext';
import { refreshToken } from '../authentication/authenticationRemoteApi';

export function* guardedGenerator(generator, action, errorHandler) {
    try {
        const result = yield generator(action);
        return result;
    } catch (exc) {
        switch (exc.errorCode) {
            case 404:
                if ('404' in errorHandler) {
                    const resources = yield select(getResourcesState);
                    yield errorHandler[404](action, resources);
                }
                yield put(raiseError(exc.errorCode));
                break;
            case 401:
                yield call(authenticationContext.removeToken);
                yield call(authenticationContext.removeLastCall);
                yield put(clearUserData());
                yield put(push(getLoginPath()));
                break;
            default:
                yield put(raiseMessage(messageTypes.ERROR_MESSAGE_TYPE, exc.errorMessage || ''));
                if (window.console) {
                    window.console.log(exc);
                }
                break;
        }
    }
}

export const guardedTakeEvery = (actionType, generatorFn) => {
    const factory = (generator) => function* inner(action) {
        yield guardedGenerator(generator, action);
    };
    return takeEvery(actionType, factory(generatorFn));
};

export function* apiCall(method, ...params) {
    if (authenticationContext.sessionExpired()) {
        yield call(authenticationContext.removeToken);
        yield put(clearUserData());
    }

    if (authenticationContext.shouldRefreshToken()) {
        const token = yield call(refreshToken);
        yield call(authenticationContext.setToken, token);
        const userTokenData = yield call(authenticationContext.getUserTokenData);
        yield put(setUserData({ ...userTokenData, isAuthenticated: true }));
    }

    yield call(authenticationContext.setLastCall, new Date());
    const result = yield call(method, ...params);
    return result;
}

export function* redirectToDifferentCulture(newCulture, redirectUrl) {
    let newUrl = redirectUrl;
    if (!newUrl) {
        newUrl = window.location.href;
    }

    newUrl = newUrl.replace(window.clientData.currentLanguage, newCulture);

    yield window.location.replace(newUrl);
}
