import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/actions/actionTypes';

export const actionReducer = (state = defaultStore.actions, action) => {
    switch (action.type) {
        case actionTypes.INIT_ACTIONS:
            return {
                ...state,
                icon: action.actions.icon,
                headingKey: action.actions.headingKey,
                subheadingKey: action.actions.subheadingKey,
                actions: action.actions.entries
            };
        case actionTypes.START_RUN_ACTION:
            return {
                ...state,
                actions: state.actions.map((entry) => {
                    const { name } = action.actionToRun;
                    return entry.name === name
                        ? { ...entry, isEnabled: false }
                        : entry;
                })
            };
        case actionTypes.FINISH_RUN_ACTION:
            return {
                ...state,
                actions: state.actions.map((entry) => {
                    const { name } = action.actionToRun;
                    return entry.name === name
                        ? { ...entry, isEnabled: true }
                        : entry;
                })
            };
        default:
            return state;
    }
};
