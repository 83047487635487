import * as actionTypes from './actionTypes';

export const fetchCmsTreeElements = (data, rootKey) => ({ type: actionTypes.FETCH_CMS_TREE_ELEMENTS, data, rootKey });
export const startFetchingCmsTreeElements = (currentNode) => ({ type: actionTypes.START_FETCHING_CMS_TREE_ELEMENTS, currentNode });
export const finishFetchingCmsTreeElements = (currentNode) => ({ type: actionTypes.FINISH_FETCHING_CMS_TREE_ELEMENTS, currentNode });
export const initCmsTreeElements = (elements, data, rootKey) => ({ type: actionTypes.INIT_CMS_TREE_ELEMENTS, elements, data, rootKey });
export const expandCmsTreeElement = (elements, currentNode, needsAddingNewElements) => ({ type: actionTypes.EXPAND_CMS_TREE_ELEMENT, elements, currentNode, needsAddingNewElements });
export const collapseCmsTreeElement = (currentNode) => ({ type: actionTypes.COLLAPSE_CMS_TREE_ELEMENT, currentNode });
export const refreshCmsTreeElement = (nodeName, nodeType) => ({ type: actionTypes.REFRESH_CMS_TREE_ELEMENT, nodeName, nodeType });
export const startRefreshingCmsTreeElements = () => ({ type: actionTypes.START_REFRESHING_CMS_TREE_ELEMENTS });
export const finishRefreshingCmsTreeElements = () => ({ type: actionTypes.FINISH_REFRESHING_CMS_TREE_ELEMENTS });
export const initRefreshingCmsTreeElements = (elements, data) => ({ type: actionTypes.INIT_REFRESHING_CMS_TREE_ELEMENTS, elements, data });
export const changeElementCmsIdInTree = (currentElementId, newElementId) => ({ type: actionTypes.CHANGE_ELEMENT_CMS_ID_IN_TREE, currentElementId, newElementId });

export const fetchCmsTreeConfig = () => ({ type: actionTypes.FETCH_CMS_TREE_CONFIG });
export const startFetchingCmsTreeConfig = () => ({ type: actionTypes.START_FETCHING_CMS_TREE_CONFIG });
export const finishFetchingCmsTreeConfig = () => ({ type: actionTypes.FINISH_FETCHING_CMS_TREE_CONFIG });
export const initCmsTreeConfig = (config) => ({ type: actionTypes.INIT_CMS_TREE_CONFIG, config });
