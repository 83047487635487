import { push as routerPush, replace as routerReplace } from 'react-router-redux';
import {
    RAISE_MESSAGE,
    CLEAR_MESSAGE,
    RAISE_ERROR,
    CLEAR_ERROR,
    SET_USER_DATA,
    CLEAR_USER_DATA,
    ADDRESSES_CHANGED
} from './actionTypes';

export const raiseMessage = (messageType, message) => ({ type: RAISE_MESSAGE, messageType, message });
export const clearMessage = (message) => ({ type: CLEAR_MESSAGE, message });
export const raiseError = (errorCode) => ({ type: RAISE_ERROR, errorCode });
export const clearError = () => ({ type: CLEAR_ERROR });

export const push = (location) => {
    if (location.startsWith(`/${window.clientData.currentLanguage}`)) {
        return routerPush(location);
    }
    return routerPush(`/${window.clientData.currentLanguage}${location}`.toLowerCase());
};
export const replace = (location) => routerReplace(`/${window.clientData.currentLanguage}${location}`);

export const setUserData = (data) => ({ type: SET_USER_DATA, data });
export const clearUserData = () => ({ type: CLEAR_USER_DATA });
export const addressesChanged = () => ({ type: ADDRESSES_CHANGED });
