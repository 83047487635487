import { Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';

import { bool } from 'prop-types';
import {
    ToastContainer,
} from 'react-toastify';
import { LoginPage } from 'views/LoginPage';
import LoaderElement from 'components/LoaderElement/LoaderElement';
import { PrivateRoute } from 'components/PrivateRoute';

const MainAppPanes = lazy(() => import('./MainAppPanes'));

const AppMain = ({ isAuthenticated }) => (
    <>
        <LoaderElement>
            <Switch>
                <Route path="/:lang/login" component={LoginPage} />
                <PrivateRoute
                    isAuthenticated={isAuthenticated}
                    path="*"
                    render={() => <MainAppPanes />} />
            </Switch>
        </LoaderElement>
        <ToastContainer />
    </>
);

AppMain.propTypes = {
    isAuthenticated: bool.isRequired
};
export default AppMain;
