export const START_FETCHING_ELEMENT_DATA = 'START_FETCHING_ELEMENT_DATA';
export const FINISH_FETCHING_ELEMENT_DATA = 'FINISH_FETCHING_ELEMENT_DATA';
export const INIT_ELEMENT_DATA = 'INIT_ELEMENT_DATA';
export const FETCH_ELEMENT_DATA = 'FETCH_ELEMENT_DATA';

export const FETCH_ELEMENT_CONFIGURATION = 'FETCH_ELEMENT_CONFIGURATION';
export const START_FETCHING_ELEMENT_CONFIGURATION = 'START_FETCHING_ELEMENT_CONFIGURATION';
export const FINISH_FETCHING_ELEMENT_CONFIGURATION = 'FINISH_FETCHING_ELEMENT_CONFIGURATION';
export const INIT_ELEMENT_CONFIGURATION = 'INIT_ELEMENT_CONFIGURATION';

export const START_SAVING_ELEMENT_DATA = 'START_SAVING_ELEMENT_DATA';
export const FINISH_SAVING_ELEMENT_DATA = 'FINISH_SAVING_ELEMENT_DATA';

export const SAVE_ELEMENT_FORM_DATA = 'SAVE_ELEMENT_FORM_DATA';
