import {
    RAISE_MESSAGE,
    CLEAR_MESSAGE,
    RAISE_ERROR,
    CLEAR_ERROR
} from './actionTypes';

export const raiseMessage = (messageType, message) => ({ type: RAISE_MESSAGE, messageType, message });
export const clearMessage = (message) => ({ type: CLEAR_MESSAGE, message });
export const raiseError = (errorCode) => ({ type: RAISE_ERROR, errorCode });
export const clearError = () => ({ type: CLEAR_ERROR });
