export const FETCH_AVAILABLE_TYPES_FOR_PARENT_METATYPE = 'FETCH_AVAILABLE_TYPES_FOR_PARENT_METATYPE';
export const START_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE = 'START_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE';
export const INIT_AVAILABLE_TYPES_FOR_PARENT_METATYPE = 'INIT_AVAILABLE_TYPES_FOR_PARENT_METATYPE';
export const FINISH_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE = 'STOP_FETCHING_AVAILABLE_TYPES_FOR_PARENT_METATYPE';

export const CREATE_NEW_ELEMENT = 'CREATE_NEW_ELEMENT';
export const START_CREATING_NEW_ELEMENT = 'START_CREATING_NEW_ELEMENT';
export const FINISH_CREATING_NEW_ELEMENT = 'FINISH_CREATING_NEW_ELEMENT';

export const OPEN_NEW_ELEMENT_MODAL = 'OPEN_NEW_ELEMENT_MODAL';
export const CLOSE_NEW_ELEMENT_MODAL = 'CLOSE_NEW_ELEMENT_MODAL';
export const TOGGLE_NEW_ELEMENT_MODAL = 'TOGGLE_NEW_ELEMENT_MODAL';
export const CLEAN_NEW_ELEMENT_MODAL = 'CLEAN_NEW_ELEMENT_MODAL';
