import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/propertyValueSuggestions/actionTypes';

export const propertyValueSuggestionsReducer = (state = defaultStore.element, action) => {
    switch (action.type) {
        case actionTypes.START_FETCHING_FIELD_SUGGESTIONS: return { ...state, propertyData: { ...state.propertyData, isFetchingSuggestions: true } };
        case actionTypes.FINISH_FETCHING_FIELD_SUGGESTIONS: return { ...state, propertyData: { ...state.propertyData, isFetchingSuggestions: false } };
        case actionTypes.INIT_FIELD_SUGGESTIONS: return {
            ...state,
            metatypeName: action.metatypeName,
            propertyData: {
                ...state.propertyData,
                propertyName: action.propertyName,
                suggestions: {
                    searchPhrase: action.searchPhrase,
                    suggestionsElements: action.suggestions
                } } };
        default: return state;
    }
};
