import { authenticationContext } from '../authentication/authenticationContext';

class Http {
    headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // 'Accept-Language': window.clientData.currentLanguage,
        dataType: 'json'
    };

    createQueryString(parameters) {
        const queryString = Object.keys(parameters).reduce((prev, current) => {
            const currentValue = parameters[current];
            if (currentValue === undefined) {
                return prev;
            }

            if (Array.isArray(currentValue)) {
                const query = currentValue.reduce((prevElement, currentElement) => (prevElement !== '' ? `${prevElement}&${current.toLowerCase()}=${currentElement}` : `${current.toLowerCase()}=${currentElement}`), '');
                return prev !== '' ? `${prev}&${query}` : query;
            }

            return prev !== '' ? `${prev}&${current.toLowerCase()}=${currentValue}` : `${current.toLowerCase()}=${currentValue}`;
        }, '');
        return queryString !== '' ? `?${queryString}` : '';
    }

    get(route, params) {
        const routeWithParams = params ? `${route.toLowerCase()}${this.createQueryString(params)}` : route.toLowerCase();
        return this.xhr(routeWithParams, null, 'GET');
    }

    put(route, params) {
        return this.xhr(route.toLowerCase(), params, 'PUT');
    }

    post(route, params) {
        return this.xhr(route.toLowerCase(), params, 'POST');
    }

    delete(route, params) {
        return this.xhr(route.toLowerCase(), params, 'DELETE');
    }

    patch(route, params) {
        return this.xhr(route.toLowerCase(), params, 'PATCH');
    }

    xhr(route, params, verb) {
        const options = { method: verb, headers: this.headers, credentials: 'include', ...(params ? { body: JSON.stringify(params) } : null) };
        const token = authenticationContext.getToken();

        if (token) {
            options.headers = { ...options.headers, Authorization: `Bearer ${token}` };
        }

        return fetch(route, options).then((resp) => {
            if (resp.ok) {
                if (resp.status === 204 || resp.headers.get('Content-Length') === '0') {
                    return;
                }

                const json = resp.json();
                return json;
            }

            const json = resp.json();
            return json
                .then((errorJson) => {
                    // errorJson represents error object returned from servers error handler middleware
                    const error = { errorCode: resp.status, ...errorJson };
                    throw error;
                })
                .catch((error) => {
                    if (error.errorCode) {
                        throw error;
                    }
                    const newError = { errorCode: resp.status };
                    throw newError;
                });
        });
    }
}

export default new Http();
