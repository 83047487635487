import { http } from 'infrastructure/http';

export const deleteElementUser = (userName, deletionMethod = 'SingleElement', deleteElementData) => {
    const uri = `/api/user-management/elements/user/${userName}/${deletionMethod}`;
    return http.delete(uri, deleteElementData);
};

export const deleteElementRole = (roleName, deletionMethod = 'SingleElement', deleteElementData) => {
    const uri = `/api/user-management/elements/role/${roleName}/${deletionMethod}`;
    return http.delete(uri, deleteElementData);
};
