import * as actionTypes from './actionTypes';

export const loginUser = (userName, password, successRedirect) => ({
    type: actionTypes.LOGIN_USER,
    userName,
    password,
    successRedirect
});
export const startUserLogin = () => ({ type: actionTypes.START_USER_LOGIN });
export const finishUserLogin = () => ({ type: actionTypes.FINISH_USER_LOGIN });
export const userAuthenticationSucceeded = () => ({ type: actionTypes.USER_AUTHENTICATION_SUCCEEDED });
export const userAuthenticationFailed = () => ({ type: actionTypes.USER_AUTHENTICATION_FAILED });
export const logoutUser = () => ({ type: actionTypes.LOGOUT_USER });
export const startUserLogout = () => ({ type: actionTypes.START_USER_LOGOUT });
export const finishUserLogout = () => ({ type: actionTypes.FINISH_USER_LOGOUT });
export const showActivateSuccessMessageAction = () => ({ type: actionTypes.SHOW_ACTIVATE_SUCCESS_MESSAGE });
