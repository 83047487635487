import * as actionTypes from './actionTypes';

export const openExportElementModal = (currentElement) => ({ type: actionTypes.OPEN_EXPORT_ELEMENT_MODAL, currentElement });
export const closeExportElementModal = () => ({ type: actionTypes.CLOSE_EXPORT_ELEMENT_MODAL });
export const toggleExportElementModal = () => ({ type: actionTypes.TOGGLE_EXPORT_ELEMENT_MODAL });
export const cleanExportElementModal = () => ({ type: actionTypes.CLEAN_EXPORT_ELEMENT_MODAL });

export const initAvailableExportDefinitions = (exportDefinitions) => ({ type: actionTypes.INIT_AVAILABLE_EXPORT_DEFINITIONS, exportDefinitions });
export const fetchAvailableExportDefinitions = () => ({ type: actionTypes.FETCH_AVAILABLE_EXPORT_DEFINITIONS });
export const addNewExportJob = (currentElement, elementMetaType, exportDefinition) => ({ type: actionTypes.ADD_NEW_EXPORT_JOB, currentElement, elementMetaType, exportDefinition });
export const startAddingNewExportJob = () => ({ type: actionTypes.START_ADDING_EXPORT_ELEMENT });
export const finishAddingNewExportJob = () => ({ type: actionTypes.FINISH_ADDING_EXPORT_ELEMENT });

export const openExportDefinitionModal = () => ({ type: actionTypes.OPEN_EXPORT_DEFINITION_MODAL });
export const closeExportDefinitionModal = () => ({ type: actionTypes.CLOSE_EXPORT_DEFINITION_MODAL });
export const toggleExportDefinitionModal = () => ({ type: actionTypes.TOGGLE_EXPORT_DEFINITION_MODAL });
export const cleanExportDefinitionModal = () => ({ type: actionTypes.CLEAN_EXPORT_DEFINITION_MODAL });
export const fetchExportDefinitionInitialOptions = () => ({ type: actionTypes.FETCH_EXPORT_DEFINITION_INITIAL_OPTIONS });
export const initExportDefinitionInitialOptions = (initialOptions) => ({ type: actionTypes.INIT_EXPORT_DEFINITIONS_INITIAL_OPTIONS, initialOptions });
export const fetchMetaTypeProperties = (selection) => ({ type: actionTypes.FETCH_META_TYPE_PROPERTIES, selection});
export const initExportDefinitionAvailableProperties = (initialOptions) => ({ type: actionTypes.INIT_EXPORT_DEFINITION_AVAILABLE_PROPERTIES, initialOptions });
export const addNewExportDefinition = (exportDefinitionName, exportMetaTypes, exportLanguages, exportDefinitionDepth, selectedProperties) => ({ type: actionTypes.ADD_NEW_EXPORT_DEFINITION, exportDefinitionName, exportMetaTypes, exportLanguages, exportDefinitionDepth, selectedProperties });
