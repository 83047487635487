import React, { Suspense } from 'react';

const LoaderElement = ({ children }) => (
    <Suspense fallback={(
        <div className="loader-container">
            <div className="loader-container-inner">
                <div className="text-center">
                    {/* <Loader type="line-scale-party" /> */}
                    Here should be the loader element.
                </div>
                <h6 className="mt-5">
                    Please wait
                    <small>Page is loading</small>
                </h6>
            </div>
        </div>
    )}
    >
        {children}
    </Suspense>
);

export default LoaderElement;
