import * as actionTypes from './actionTypes';

export const copyElement = (currentElement, copyOption, newElementId, elementArea) => ({ type: actionTypes.COPY_ELEMENT, currentElement, copyOption, newElementId, elementArea });
export const startCopyElement = () => ({ type: actionTypes.START_COPYING_ELEMENT });
export const finishCopyElement = () => ({ type: actionTypes.FINISH_COPYING_ELEMENT });

export const openCopyElementModal = (currentElement) => ({ type: actionTypes.OPEN_COPY_ELEMENT_MODAL, currentElement });
export const closeCopyElementModal = () => ({ type: actionTypes.CLOSE_COPY_ELEMENT_MODAL });
export const toggleCopyElementModal = () => ({ type: actionTypes.TOGGLE_COPY_ELEMENT_MODAL });
export const cleanCopyElementModal = () => ({ type: actionTypes.CLEAN_COPY_ELEMENT_MODAL });
