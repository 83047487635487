import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/userManagement/changeElementType/actionTypes';

export const changeElementTypeReducer = (state = defaultStore.userManagement.changeElementType, action) => {
    switch (action.type) {
        case actionTypes.INIT_AVAILABLE_TYPES:
            return { ...state, availableTypes: action.types };
        case actionTypes.OPEN_CHANGE_TYPE_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_CHANGE_TYPE_MODAL:
            return { ...state, isModalOpen: false };
        case actionTypes.TOGGLE_CHANGE_TYPE_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        default:
            return state;
    }
};
