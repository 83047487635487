export const START_FETCHING_SEARCH_TERM = 'START_FETCHING_SEARCH_TERM';
export const FINISH_FETCHING_SEARCH_TERM = 'FINISH_FETCHING_SEARCH_TERM';
export const FETCH_SEARCH_TERM = 'FETCH_SEARCH_TERM';
export const INIT_SEARCH_TERM_DATA = 'INIT_SEARCH_TERM_DATA';

export const INIT_SEARCH_TERM = 'INIT_SEARCH_TERM';

export const START_FETCHING_ALL_ELEMENTS = 'START_FETCHING_ALL_ELEMENTS';
export const FINISH_FETCHING_ALL_ELEMENTS = 'FINISH_FETCHING_ALL_ELEMENTS';
export const FETCH_ALL_ELEMENTS = 'FETCH_ALL_ELEMENTS';
export const INIT_ALL_ELEMENTS = 'INIT_ALL_ELEMENTS';

export const START_FETCHING_EXPORT = 'START_FETCHING_EXPORT';
export const FINISH_FETCHING_EXPORT = 'FINISH_FETCHING_EXPORT';
export const FETCH_EXPORT = 'FETCH_EXPORT';
export const INIT_EXPORT = 'INIT_EXPORT';

export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
