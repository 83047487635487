import defaultStore from 'store/defaultStore';
import * as actionTypes from 'api/catalog/deleteElement/actionTypes';

export const deleteElementReducer = (state = defaultStore.catalog.deleteElement, action) => {
    switch (action.type) {
        case actionTypes.START_DELETING_ELEMENT:
            return { ...state, isDeletingElement: true };
        case actionTypes.FINISH_DELETING_ELEMENT:
            return { ...state, isDeletingElement: false };
        case actionTypes.OPEN_DELETE_ELEMENT_MODAL:
            return { ...state, isModalOpen: true, currentElement: { ...action.currentElement } };
        case actionTypes.CLOSE_DELETE_ELEMENT_MODAL:
            return { ...state, isModalOpen: false, currentElement: null };
        case actionTypes.TOGGLE_DELETE_ELEMENT_MODAL:
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                currentElement: state.isModalOpen ? null : state.currentElement
            };
        case actionTypes.CLEAN_DELETE_ELEMENT_MODAL:
            return {
                ...state,
                currentElement: null,
                deletionMethod: { ...action.deletionMethod }
            };
        default:
            return state;
    }
};
