import { put, select } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { push } from 'infrastructure/messaging/actions';
import { getResourcesState } from 'store/layout/selectors';
import { copyElement as copyElementApi } from './remoteApi';

import * as actions from './actions';
import * as actionTypes from './actionTypes';
import { refreshUserRoleParents } from '../sagaHelpers';

function* copyElementInApi(action) {
    const copyElementRequest = {
        newElementId: action.newElementId,
        copyOption: action.copyOption
    };
    yield apiCall(copyElementApi, action.currentElement.id, action.currentElement.metaTypeName, copyElementRequest);
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.copyElement_SuccessMessage));
    yield put(actions.closeCopyElementModal());
    yield put(actions.cleanCopyElementModal());
    yield refreshUserRoleParents(action);
    yield (put(push(`/user-management/${action.currentElement.metaTypeName}/${action.newElementId}`)));
}

function* copyElement(action) {
    yield put(actions.startCopyElement());
    yield guardedGenerator(copyElementInApi, action);
    yield put(actions.finishCopyElement());
}

export function* watchCopyElement() {
    yield guardedTakeEvery(actionTypes.COPY_ELEMENT, copyElement);
}
