import { put, select } from 'redux-saga/effects';
import { push } from 'infrastructure/messaging/actions';
import diff from 'object-diff';
import { guardedTakeEvery, apiCall, guardedGenerator } from 'infrastructure/helpers/sagasHelper';
import { getFormInitialValues } from 'redux-form';
import { raiseMessage } from 'infrastructure/errorHandling/actions';
import * as messageTypes from 'infrastructure/messaging/messageTypes';
import { getResourcesState } from 'store/layout/selectors';
import * as actions from './actions';
import * as actionTypes from './actionTypes';
import {
    getElementDataApi,
    getElementConfigurationApi,
    saveElementDataApi
} from './remoteApi';

const elmentArea = 'user-management';

function* elementNotFound(action, resources) {
    yield put(raiseMessage(messageTypes.ERROR_MESSAGE_TYPE, resources.elementNotFound));
    yield (put(push(`/${elmentArea}/${action.elementMetaType}`)));
}

function* getElementDataInApi(action) {
    const elementData = yield apiCall(getElementDataApi, action.elementId, action.elementMetaType);
    if (elementData) {
        yield put(actions.initElementData(elementData));
    }
}

function* getElementData(action) {
    const errorHandler = { 404: elementNotFound };
    yield guardedGenerator(getElementDataInApi, action, errorHandler);
}

function* fetchElementData(action) {
    yield put(actions.startFetchingElementData());
    yield getElementData(action);
    yield put(actions.finishFetchingElementData());
}

export function* watchFetchElementData() {
    yield guardedTakeEvery(actionTypes.FETCH_ELEMENT_DATA, fetchElementData);
}

function* getElementConfiguration(action) {
    const elementConfigurationData = yield apiCall(getElementConfigurationApi, action.elementMetaType, action.elementType);
    yield put(actions.initElementConfiguration(elementConfigurationData));
}

function* fetchElementConfiguration(action) {
    yield put(actions.startFetchingElementConfiguration());
    yield getElementConfiguration(action);
    yield put(actions.finishFetchingElementConfiguration());
}

export function* watchFetchElementConfiguration() {
    yield guardedTakeEvery(actionTypes.FETCH_ELEMENT_CONFIGURATION, fetchElementConfiguration);
}

function* getElementDataApiCall(action) {
    const initialValues = yield select(getFormInitialValues(action.formName));
    const delta = diff(initialValues, action.form);
    const associationsDelta = diff(initialValues.associations, action.form.associations);
    const requestBody = {
        propertiesData: { ...delta, associations: undefined },
        associations: { ...associationsDelta },
        elementArea: action.elementArea
    };
    yield apiCall(saveElementDataApi, action.elementMetaType, action.elementId, requestBody);
    const resources = yield select(getResourcesState);
    yield put(raiseMessage(messageTypes.SUCCESS_MESSAGE_TYPE, resources.saveElement_SuccessMessage));
    const elementData = yield apiCall(getElementDataApi, action.elementId, action.elementMetaType);
    yield put(actions.initElementData(elementData));
}

function* saveElementFormData(action) {
    yield put(actions.startSavingElementData());
    yield guardedGenerator(getElementDataApiCall, action);
    yield put(actions.finishSavingElementData());
}

export function* watchSaveElementFormData() {
    yield guardedTakeEvery(actionTypes.SAVE_ELEMENT_FORM_DATA, saveElementFormData);
}
