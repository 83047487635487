import { connect } from 'react-redux';
import { withResources } from 'infrastructure/localization';
import { getAuthenticationFailed, getIsAuthenticating } from 'store/user/selectors';
import { loginUser } from 'api/user/login/actions';
import { reduxForm } from 'redux-form';
import LoginForm from '../components/LoginForm';

const mapStateToProps = (state) => ({
    authenticationFailed: getAuthenticationFailed(state),
    isAuthenticating: getIsAuthenticating(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onSubmit: (formValues) => {
        dispatch(loginUser(formValues.email, formValues.password, ownProps.redirectUrl));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'login' })(withResources(LoginForm)));
