import { put } from 'redux-saga/effects';
import { guardedTakeEvery, apiCall } from 'infrastructure/helpers/sagasHelper';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import { getLayoutApi } from './remoteApi';

function* getLayoutInfo() {
    const layoutInfo = yield apiCall(getLayoutApi);
    yield put(actions.initLayoutInfo(layoutInfo));
}

function* fetchLayoutInfo() {
    yield put(actions.startFetchingLayoutInfo());
    yield getLayoutInfo();
    yield put(actions.finishFetchingLayoutInfo());
}

export function* watchFetchLayoutInfo() {
    yield guardedTakeEvery(actionTypes.FETCH_LAYOUT_INFO, fetchLayoutInfo);
}
